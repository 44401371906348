// SSGではhydrationが終わらないと useAsyncData がキャッシュを見続けるので、hydrationが終わってrefreshを叩くためのユーティリティ関数が必要
export const waitHydrated = async () => {
  const nuxt = useNuxtApp();

  return new Promise<void>((resolve) => {
    const waitUntilHydrated = () => {
      if (nuxt.isHydrating === false) {
        resolve();
        return;
      }
      requestAnimationFrame(waitUntilHydrated);
    };
    waitUntilHydrated();
  });
};
