import { stringifyQuery } from './stringifyQuery';
import type { RouterConfig } from '@nuxt/schema';
import type { FunctionalComponent } from 'vue';

const NotFound: FunctionalComponent = () => {
  throw createError({
    statusCode: 404,
  });
};

const config: RouterConfig = {
  routes(routes) {
    return [
      ...routes,
      {
        name: '404',
        path: '/:pathMatch(.*)',
        // promiseにしないとwarnが出る
        component: async () => NotFound,
      },
    ];
  },
  stringifyQuery,
};

export default config;
