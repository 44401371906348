type TitleArgs = {
  title?: string;
  hasTitleSuffix?: boolean;
  titleSuffix: string;
  defaultTitle: string;
};

export const generateAppMetaTitle = (args: TitleArgs) => {
  const titleSuffixArg = args.titleSuffix;
  const hasTitleSuffixArg = args.hasTitleSuffix ?? true;
  const titleSuffix = hasTitleSuffixArg ? ` - ${titleSuffixArg}` : '';

  if (toValue(args.title) === undefined) {
    const { defaultTitle } = args;
    return defaultTitle;
  }
  return `${toValue(args.title)}${titleSuffix}`;
};
