import { resolveServerEndpoints } from '~theme/libs/serverEndpoints';

export const useServiceEndpoints = () => {
  const {
    public: { serverEnv, appOrigin },
  } = useRuntimeConfig();

  const shadowApiEndpoint = `${appOrigin}/internal/api/v1`;

  const endpoints = resolveServerEndpoints(serverEnv);
  return { ...endpoints, shadow: shadowApiEndpoint };
};
