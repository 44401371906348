type Sessions = {
  longSessionId: string;
  shortSessionId: string;
};

type SessionsResponse = {
  LongSession: {
    ID: string;
    Expire: string;
  };
  ShortSession: {
    ID: string;
    Expire: string;
  };
};

const useServerFetchSessions = () => {
  const { bigbrother: bigbrotherEndpoint } = useServiceEndpoints();
  const bigbrotherSessionToken = useCookie('_bigbrother');

  const fetch = async (): Promise<Sessions> => {
    const result = await $fetch<SessionsResponse>('/session', {
      baseURL: bigbrotherEndpoint,
      credentials: 'include',
      headers: {
        cookie: `_bigbrother=${bigbrotherSessionToken.value};`,
      },
    });

    return {
      longSessionId: result.LongSession.ID,
      shortSessionId: result.ShortSession.ID,
    };
  };

  return { fetch };
};

const useClientFetchSessions = () => {
  const { bigbrother: bigbrotherEndpoint } = useServiceEndpoints();

  const fetch = async (): Promise<Sessions> => {
    const result = await $fetch<SessionsResponse>('/session', {
      baseURL: bigbrotherEndpoint,
      credentials: 'include',
      mode: 'cors',
      headers: {
        'cache-control': 'no-cache',
        pragma: 'no-cache',
      },
    });

    return {
      longSessionId: result.LongSession.ID,
      shortSessionId: result.ShortSession.ID,
    };
  };

  return { fetch };
};

export default defineNuxtPlugin(async (nuxtApp) => {
  const { setSession } = useSession();
  if (import.meta.env.MODE === 'test') {
    // テスト時はbigbrotherを叩かない
    setSession('test-long-session-id', 'test-short-session-id');
    return;
  }

  if (import.meta.server) {
    // SSR: falseの場合は呼ばれない
    const { fetch } = useServerFetchSessions();
    const result = await fetch();
    // hydrationのstateでclientに渡す
    nuxtApp.payload.data['bigbrother-sessions'] = result;

    const { longSessionId, shortSessionId } = result;
    setSession(longSessionId, shortSessionId);
    return;
  }
  // process.client
  const { fetch } = useClientFetchSessions();

  // hydrationのstateをserverから受け取る
  const hydrated = nuxtApp.payload.data[
    'bigbrother-sessions'
  ] as Maybe<Sessions>;
  if (hydrated) {
    const { longSessionId, shortSessionId } = hydrated;
    setSession(longSessionId, shortSessionId);
  } else {
    const { longSessionId, shortSessionId } = await fetch();
    setSession(longSessionId, shortSessionId);
  }

  const router = useRouter();
  router.afterEach(async () => {
    const { longSessionId, shortSessionId } = await fetch();
    setSession(longSessionId, shortSessionId);
  });
});
