import type { ChannelId } from '@/entity/channel';
import { DEFAULT_CHANNEL_ID, toChannel } from '@/entity/channel';

// TODO: useSessionChannelIdにrenameする
// ていうか、useSessionChannel使おう！
export const useChannelId = () => {
  const { logger } = useLogger();

  try {
    const channelId = useSessionStorageState<ChannelId>(
      '__channelId',
      DEFAULT_CHANNEL_ID
    );

    const setChannelId = (newChannelId: ChannelId) => {
      logger.value?.event.setChannelId({
        channelId: channelId.value,
        newChannelId,
      });
      channelId.value = newChannelId;
    };

    return { channelId, setChannelId };
  } catch (_e) {
    // シークレットモードでStorage使えない場合がある
    return { channelId: toRef(DEFAULT_CHANNEL_ID), setChannelId: () => {} };
  }
};

export const useSessionChannel = () => {
  if (import.meta.server) {
    throw Error('useSessionChannel does not work on the server-side');
  }

  const { channelId } = useChannelId();

  const channel = computed(() => toChannel(channelId.value));

  return { channel, channelId };
};
