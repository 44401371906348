const UNINITIALIZED: unique symbol = Symbol('uninitialized lazy value');
type UNINITIALIZED = typeof UNINITIALIZED;

class Lazy<T> {
  private initializer: () => T;
  private value_: T | UNINITIALIZED = UNINITIALIZED;

  constructor(initializer: () => T) {
    this.initializer = initializer;
  }

  get value(): T {
    if (this.value_ !== UNINITIALIZED) return this.value_;
    return (this.value_ = this.initializer());
  }
}

export const lazy = <T>(proc: () => T) => new Lazy(proc);
