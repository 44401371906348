import type { HotelToken } from './useCredentialManager/hotel';
import type { ComputedRef } from 'vue';

// 依存の発生源が singleton なのでこっちも singleton にしとくと callbackとかの
// 寿命を考えるのが楽になるし状態の変更関係のコードをここに押し込められて嬉しい
const hotelToken = lazy(() => {
  const { tokenManager } = useHotelTokenManager();
  tokenManager.addOnCredentialChangedListener((token_) => {
    token.value = token_;
  });
  const token = ref<HotelToken | null>(tokenManager.credential);
  return token;
});
const isAuthenticated = lazy(() =>
  computed(() => hotelToken.value.value != null)
);

type Return = {
  isAuthenticated: ComputedRef<boolean>;
};

export const useAuthenticationState = (): Return & PromiseLike<Return> => {
  const { tokenManager } = useHotelTokenManager();
  const returnValue: Return = {
    // singleton の方をそのまま返してしまうと、components とかで自由に watch で
    // きなくなる（メモリリークの原因になる）ので自由に使いやすいように新しい
    // ref を作る
    isAuthenticated: computed(() => isAuthenticated.value.value),
  };
  const initializedPromise = tokenManager.then(() => returnValue);
  // await したら初回の token 有効性確認を待つ
  return {
    ...returnValue,
    then: (onfullfilled?, onrejected?) =>
      initializedPromise.then(onfullfilled, onrejected),
  };
};
