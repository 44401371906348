type ImageArgs = {
  imageUrl?: string;
  imagePath?: string;
  defaultOgImagePath: string;
};

export const generateAppMetaImage = (appOrigin: string, args: ImageArgs) => {
  const { imageUrl, imagePath, defaultOgImagePath } = args;

  if (imageUrl != null) {
    return imageUrl;
  }
  if (imagePath != null) {
    return new URL(imagePath, appOrigin).toString();
  }
  return new URL(defaultOgImagePath, appOrigin).toString();
};
