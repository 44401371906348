import { createUseAppMeta } from './useAppMeta/createUseAppMeta';

export const useAppMeta = createUseAppMeta({
  titleSuffix: 'オカネコ',
  defaultTitle: 'オカネコ - 3分でかんたん家計診断',
  defaultDescription: `累計ユーザー数${
    useConstants().USER_STATS.countIn10K
  }万人突破／オカネコは、国内最大級の家計診断・相談サービス。サービス利用の満足度98%以上。お金のことならすべてオカネコにお任せ。TVCMも好評放映中！`,
  defaultOgImagePath: '/ogp/default.png',
});

export const useSeminarAppMeta = createUseAppMeta({
  titleSuffix: 'オカネコマネーセミナー',
  defaultTitle:
    'オカネコマネーセミナー｜お金に関するセミナー・勉強ならオカネコ',
  defaultDescription:
    'オカネコマネーセミナーは国内最大級の家計診断・お金に関する相談サービスを提供するオカネコが提供するオンラインマネーセミナーです。スマホやPCがあれば、全国どこからでもセミナー参加可能です。',
  defaultOgImagePath: '/ogp/seminar.jpg',
});
