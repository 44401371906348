import {
  type MaybeRefOrGetter,
  type RemovableRef,
  type UseStorageOptions,
  syncRef,
  // 自身なのでdisable
  // eslint-disable-next-line no-restricted-imports
  useLocalStorage,
  useSessionStorage,
} from '@vueuse/core';
import { keyByFile } from '~theme/utils/keyByFile';

// useLocalStorageを使うと、タブ間では状態がリアクティブに同期されるが、
// 同一タブ内では同期されない。storage eventがタブ内では発火しないため。
// そのため、useStateを使って、タブ内でも同期されるようにした。
export function useLocalStorageState(
  key: string,
  initialValue: MaybeRefOrGetter<string>,
  options?: UseStorageOptions<string>
): RemovableRef<string>;
export function useLocalStorageState(
  key: string,
  initialValue: MaybeRefOrGetter<boolean>,
  options?: UseStorageOptions<boolean>
): RemovableRef<boolean>;
export function useLocalStorageState(
  key: string,
  initialValue: MaybeRefOrGetter<number>,
  options?: UseStorageOptions<number>
): RemovableRef<number>;
export function useLocalStorageState<T>(
  key: string,
  initialValue: MaybeRefOrGetter<T>,
  options?: UseStorageOptions<T>
): RemovableRef<T>;
export function useLocalStorageState<T = unknown>(
  key: string,
  initialValue: MaybeRefOrGetter<null>,
  options?: UseStorageOptions<T>
): RemovableRef<T>;
export function useLocalStorageState(
  key: string,
  initialValue: MaybeRefOrGetter<any>,
  options?: UseStorageOptions<any>
): RemovableRef<any> {
  try {
    const storedValue = useLocalStorage(key, initialValue, options);
    const state = useState(
      keyByFile(import.meta, `localStorage:${key}`),
      () => storedValue
    );
    syncRef(state, storedValue);
    return state;
  } catch (e) {
    // シークレットモードでStorage使えない場合がある
    return useState(
      keyByFile(import.meta, `localStorage:${key}`),
      () => initialValue
    );
  }
}

export function useSessionStorageState(
  key: string,
  initialValue: MaybeRefOrGetter<string>,
  options?: UseStorageOptions<string>
): RemovableRef<string>;
export function useSessionStorageState(
  key: string,
  initialValue: MaybeRefOrGetter<boolean>,
  options?: UseStorageOptions<boolean>
): RemovableRef<boolean>;
export function useSessionStorageState(
  key: string,
  initialValue: MaybeRefOrGetter<number>,
  options?: UseStorageOptions<number>
): RemovableRef<number>;
export function useSessionStorageState<T>(
  key: string,
  initialValue: MaybeRefOrGetter<T>,
  options?: UseStorageOptions<T>
): RemovableRef<T>;
export function useSessionStorageState<T = unknown>(
  key: string,
  initialValue: MaybeRefOrGetter<null>,
  options?: UseStorageOptions<T>
): RemovableRef<T>;
export function useSessionStorageState(
  key: string,
  initialValue: MaybeRefOrGetter<any>,
  options?: UseStorageOptions<any>
): RemovableRef<any> {
  try {
    const storedValue = useSessionStorage(key, initialValue, options);
    const state = useState(
      keyByFile(import.meta, `localStorage:${key}`),
      () => storedValue
    );
    syncRef(state, storedValue);
    return state;
  } catch (e) {
    // シークレットモードでStorage使えない場合がある
    return useState(
      keyByFile(import.meta, `localStorage:${key}`),
      () => initialValue
    );
  }
}
