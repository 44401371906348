import type { RouteLocationNormalized } from '#vue-router';

export type ValueOfQuery =
  RouteLocationNormalized['query'][keyof RouteLocationNormalized['query']];

export const getStringQuery = (query: ValueOfQuery): Maybe<string> =>
  (Array.isArray(query) ? query[0] : query) || undefined;

export const getIntQuery = (query: ValueOfQuery): Maybe<number> => {
  const stringValue = getStringQuery(query);
  if (stringValue === undefined) {
    return undefined;
  }
  const intValue = parseInt(stringValue, 10);
  return Number.isNaN(intValue) ? undefined : intValue;
};
