import { defineAsyncComponent } from 'vue'
export default {
  default: defineAsyncComponent(() => import("/home/runner/_work/shadow/shadow/packages/app/src/layouts/default.vue").then(m => m.default || m)),
  exclusive: defineAsyncComponent(() => import("/home/runner/_work/shadow/shadow/packages/app/src/layouts/exclusive.vue").then(m => m.default || m)),
  home: defineAsyncComponent(() => import("/home/runner/_work/shadow/shadow/packages/app/src/layouts/home.vue").then(m => m.default || m)),
  my: defineAsyncComponent(() => import("/home/runner/_work/shadow/shadow/packages/app/src/layouts/my.vue").then(m => m.default || m)),
  "no-footer": defineAsyncComponent(() => import("/home/runner/_work/shadow/shadow/packages/app/src/layouts/no-footer.vue").then(m => m.default || m)),
  "partner-ponta": defineAsyncComponent(() => import("/home/runner/_work/shadow/shadow/packages/app/src/layouts/partner-ponta.vue").then(m => m.default || m)),
  "partner-smcc": defineAsyncComponent(() => import("/home/runner/_work/shadow/shadow/packages/app/src/layouts/partner-smcc.vue").then(m => m.default || m)),
  plain: defineAsyncComponent(() => import("/home/runner/_work/shadow/shadow/packages/app/src/layouts/plain.vue").then(m => m.default || m)),
  register: defineAsyncComponent(() => import("/home/runner/_work/shadow/shadow/packages/app/src/layouts/register.vue").then(m => m.default || m))
}