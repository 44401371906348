import type { InjectionKey } from '#imports';
import type { RouteLocationNormalizedGeneric } from '#vue-router';
import { createShadowLogger } from '@400f/ltl-people';
import { isEqual } from 'ohash';

type Logger = ReturnType<typeof createShadowLogger>;
export const LoggerKey: InjectionKey<Logger> = Symbol('LoggerKey');

export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.env.MODE === 'test') return;
  const router = useRouter();
  const {
    public: { serverEnv: originalServerEnv },
  } = useRuntimeConfig();
  const { bigbrother } = useServiceEndpoints();

  const serverEnv = originalServerEnv === 'local' ? 'alpha' : originalServerEnv;

  const logger = createShadowLogger({
    baseUrl: bigbrother,
    env: serverEnv,
    onError: () => {
      // TODO: sentryにエラーを送る
      // app.$sentry?.captureException(e);
    },
  });

  const metaArgs = useLoggerMetaArgs();

  nuxtApp.vueApp.provide(LoggerKey, logger);

  logger.page.view({ ...metaArgs.value, referer: document.referrer });

  const isNotRedirectedFirstAccess = (
    to: RouteLocationNormalizedGeneric,
    from: RouteLocationNormalizedGeneric
  ) => {
    return (
      to.path == from.path &&
      to.hash == from.hash &&
      isEqual(to.query, from.query)
    );
  };

  router.afterEach((to, from) => {
    if (isNotRedirectedFirstAccess(to, from)) {
      return;
    }
    const referer = new URL(from.fullPath, window.location.origin).toString();
    logger?.page.view({ ...metaArgs.value, referer });
  });

  router.beforeResolve((to, from) => {
    if (isNotRedirectedFirstAccess(to, from)) {
      return;
    }
    logger?.page.hide({
      ...metaArgs.value,
      url: new URL(from.fullPath, window.location.origin).toString(),
    });
  });

  window.addEventListener('unload', async () => {
    await logger?.page.hide(metaArgs.value);
  });
});
