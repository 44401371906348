import { INFLOW_CHANNEL_QUERY_NAME, isChannelId } from '~/entity/channel';

export default defineNuxtPlugin(() => {
  const route = useRoute();
  const channel = getIntQuery(route.query[INFLOW_CHANNEL_QUERY_NAME]);

  if (!channel || !isChannelId(channel)) {
    return;
  }

  const { setChannelId } = useChannelId();
  setChannelId(channel);
});
