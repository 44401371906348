import { LoggerKey } from '@/plugins/logger.client';

export const useLogger = () => {
  const {
    public: { isHistoire },
  } = useRuntimeConfig();
  if (import.meta.server || isHistoire) return { logger: ref(null) };

  const logger = useRawLogger();
  const metaArgs = useLoggerMetaArgs();

  /* eslint-disable @typescript-eslint/no-explicit-any */
  // loggerを使うたびにuseMetaArgs()したくないので、
  // 第一引数にMetaArgsを埋め込んだ関数を返す
  type OmitFirstArg<F> = F extends (x: any, ...args: infer P) => infer R
    ? (...args: P) => R
    : never;
  type Logger = typeof logger;
  type WrappedLogger = {
    [K in keyof Logger]: {
      [P in keyof Logger[K]]: OmitFirstArg<Logger[K][P]>;
    };
  };
  const wrappedLogger = computed(() => {
    return Object.fromEntries(
      Object.entries(logger).map(([categoryKey, categoryLoggers]) => {
        return [
          categoryKey,
          Object.fromEntries(
            Object.entries(categoryLoggers).map(([actionKey, actionLogger]) => {
              return [
                actionKey,
                (...args: any[]) =>
                  actionLogger(metaArgs.value, ...args).catch(captureException),
              ];
            })
          ),
        ];
      })
    ) as unknown as WrappedLogger;
  });
  /* eslint-enable @typescript-eslint/no-explicit-any */

  return {
    logger: wrappedLogger,
  };
};

// pageViewIdを一意にするため、インスタンスの実体を一つにしたい
export const useRawLogger = () => {
  const logger = inject(LoggerKey);
  if (!logger) {
    throw new Error('useRawLogger() must be used after LoggerKey is provided');
  }
  return logger;
};

type MetaArgs = {
  apiToken: Maybe<string>;
  diagnosisId: Maybe<string>;
  referer: string;
};

export const useLoggerMetaArgs = () => {
  const { token } = useHotelToken();
  return computed<MetaArgs>(() => ({
    apiToken: token.value == null ? undefined : token.value.serialize(),
    diagnosisId: undefined, // @ryoasu に確認して、いらないよとのことなので落とす
    referer: '', // リファラが必要なログは plugins/logger.client.ts でハンドリングしている
  }));
};
