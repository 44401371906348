import validate from "/home/runner/_work/shadow/shadow/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.9_db0@0.2.1_eslint@9.21.0_jiti@2.4._1402de71b07b515abb6f0c34345cdb6e/node_modules/nuxt/dist/pages/runtime/validate.js";
import sticky_45query_45params from "/home/runner/_work/shadow/shadow/packages/app/src/modules/stickyQueryParams/runtime/middleware/index.ts";
import manifest_45route_45rule from "/home/runner/_work/shadow/shadow/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.9_db0@0.2.1_eslint@9.21.0_jiti@2.4._1402de71b07b515abb6f0c34345cdb6e/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  sticky_45query_45params,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "activation-check": () => import("/home/runner/_work/shadow/shadow/packages/app/src/middleware/activation-check.ts"),
  "auth-check": () => import("/home/runner/_work/shadow/shadow/packages/app/src/middleware/auth-check.ts"),
  "canonicalize-path": () => import("/home/runner/_work/shadow/shadow/packages/app/src/middleware/canonicalize-path.ts")
}