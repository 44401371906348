import { hash } from 'ohash';

/**
 * ファイルのパスをベースにハッシュ化したキーを返すよ
 *
 * @param importMeta import.metaを渡してね
 * @param additionalKey ファイル内で複数のキーを作りたい時に使ってね
 * @returns ファイルのパスをベースにハッシュ化したキー
 */
export const keyByFile = (importMeta: ImportMeta, additionalKey?: string) => {
  // additionalKeyがたまたま存在するファイルのパスと被ってしまわないように、
  // ファイルパスの段階でハッシュ化しておく
  const keyByFile = hash(importMeta.url);
  return `${keyByFile}${additionalKey ? `:${additionalKey}` : ''}`;
};
