import { graphql } from '@/gql';
import { useNuxtApp } from 'nuxt/app';

export default defineNuxtPlugin(async () => {
  if (import.meta.env.MODE === 'test') return;
  const { tokenManager } = await useHotelTokenManager();
  const { refetch, result } = useQuery(
    graphql(`
      query UpdateViewerWithAuthenticationStateChange {
        viewer2 {
          ... on ViewerQueryErrorPayload {
            error
          }
          ... on ViewerQuerySuccessPayload {
            result {
              id
              nickname
            }
          }
        }
      }
    `),
    {},
    {
      // キャッシュを飛ばすためにfetchするのでnetwork-onlyで問い合わせる
      fetchPolicy: 'network-only',
    }
  );
  const app = useNuxtApp();
  tokenManager.addOnCredentialChangedListener(async () => {
    await refetch();
    if (result.value?.viewer2.__typename !== 'ViewerQuerySuccessPayload')
      return;
    app.$sentry.setUser(result.value.viewer2.result);
  });
  useInitializeNotifications();
});
