import type { PlannerId, PlannerSex } from '@/types/app';
import { useServiceEndpoints } from 'theme/composables/useServiceEndpoints';

export type ImgixAvatarElement = {
  uuid: string;
  ext: string;
};
export type ImgixOptions = {
  width?: number;
  height?: number;
  quality?: number;
  mask?: 'ellipse'; // 必要があれば他のmaskも対応する
  faceFit?: boolean;
};

const defaultAvatarImgixOptions: Required<ImgixOptions> = {
  width: 160,
  height: 160,
  quality: 70,
  mask: 'ellipse',
  faceFit: true,
};

const avatarParams = (options: ImgixOptions): Required<ImgixOptions> => ({
  ...defaultAvatarImgixOptions,
  ...options,
});

const createImgixURLBuilder =
  (baseURL: URL) =>
  (path: ImgixImagePath, options: ImgixOptions = {}): string => {
    const url = new URL(path, baseURL);
    const params: { [_: string]: string | undefined } = {
      auto: 'format',
      w: options?.width?.toString(),
      h: options?.height?.toString(),
      q: options?.quality?.toString(),
      mask: options?.mask,
      ...(options?.faceFit
        ? {
            fit: 'facearea',
            facepad: '3',
          }
        : {}),
    };
    for (const [key, value] of Object.entries(params)) {
      if (value === undefined) continue;
      url.searchParams.set(key, value);
    }
    return url.toString();
  };

const createPlannerAvatarURLBuilder =
  (buildImgixUrl: ReturnType<typeof createImgixURLBuilder>) =>
  (
    plannerId: PlannerId,
    imgixAvatarElement: ImgixAvatarElement,
    options: ImgixOptions = {}
  ) =>
    buildImgixUrl(
      `/avatar/${plannerId}/${imgixAvatarElement.uuid}.${imgixAvatarElement.ext}` as ImgixImagePath,
      avatarParams(options)
    );

/**
 *
 * @deprecated これまではジェンダー別に画像の出しわけをしていたが、性別関わらず共通のdefault.pngを使うようにする。
 * サムネイルの表示はほぼ確実にPlannerThumbnail.vueを使うので、コンポーネント内にベタ書きされているfallback画像を使う。
 *
 * @param sex
 * @param options
 * @returns
 */
const createDefaultPlannerAvatarURLBuilder =
  (buildImgixUrl: ReturnType<typeof createImgixURLBuilder>) =>
  (sex: PlannerSex, options: ImgixOptions = {}) =>
    buildImgixUrl(
      `/avatar/0-default/${sex}.png` as ImgixImagePath,
      avatarParams(options)
    );

export const useImgixURL = () => {
  const config = useServiceEndpoints();
  const baseURL = new URL(config.imgix);
  const imgixURLBuilder = createImgixURLBuilder(baseURL);

  return {
    buildImgixURL: imgixURLBuilder,
    buildPlannerAvatarURL: createPlannerAvatarURLBuilder(imgixURLBuilder),

    // @deprecated
    buildDefaultPlannerAvatarURL:
      createDefaultPlannerAvatarURLBuilder(imgixURLBuilder),
  };
};
