import { keyByFile } from '~theme/utils/keyByFile';

type ModalKey = string;
type ModalState = 'open' | 'close';

const useEntireModalSharedState = () =>
  useState<Record<ModalKey, ModalState>>(
    keyByFile(import.meta, 'modalsOpenState'),
    () => ({})
  );

export const useModalStateByKey = (key: ModalKey) => {
  const entireModalSharedState = useEntireModalSharedState();

  const isModalOpened = computed<boolean>({
    get() {
      return entireModalSharedState.value[key] === 'open';
    },
    set(value) {
      entireModalSharedState.value = {
        ...entireModalSharedState.value,
        [key]: value ? 'open' : 'close',
      };
    },
  });

  return isModalOpened;
};

export const useEntireModal = () => {
  const entireState = useEntireModalSharedState();
  const isSomeModalOpened = computed<boolean>(() => {
    return Object.values(entireState.value).some(
      (modalState) => modalState === 'open'
    );
  });

  return {
    isSomeModalOpened,
  };
};
