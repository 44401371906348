import { createGtm } from '@gtm-support/vue-gtm';

export default defineNuxtPlugin(
  async ({
    vueApp,
    $config: {
      public: { gtmId, lmGtmId },
    },
  }) => {
    if (import.meta.env.MODE === 'test') return;

    // localStorageが使えない環境ではGTMを起動しない。
    // chromeのシークレットブラウザなど、localStorageへのアクセスでエラーが起きる環境ではそもそもGTMのタグがほとんど動かないので、
    // エラーを避けるためにGTMを起動しないようにする。
    // ref: https://400f-jp.slack.com/archives/C019QN8SASD/p1697175275487519?thread_ts=1697172156.228459&cid=C019QN8SASD
    // LM的にはシークレットブラウザでもコンソールにエラーが出るとQA通らないっぽい。
    try {
      window.localStorage.getItem('__CanIUseLocalStorage?__');
    } catch (e) {
      return;
    }

    const {
      public: { serverEnv },
    } = useRuntimeConfig();
    const router = useRouter();

    const ids = [...(gtmId ? [gtmId] : []), ...(lmGtmId ? [lmGtmId] : [])];
    if (ids.length === 0) return;

    vueApp.use(
      createGtm({
        id: ids,
        debug: serverEnv !== 'production',
        loadScript: true,
        vueRouter: router,
      })
    );
  }
);
