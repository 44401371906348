type UrlArgs = {
  urlPath?: string;
};

export const generateAppMetaUrl = (appOrigin: string, args: UrlArgs) => {
  const { urlPath } = args;

  const url =
    urlPath != null ? new URL(urlPath, appOrigin).toString() : undefined;

  return url;
};
