/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line no-restricted-imports
import { type FragmentType, useFragment } from '@/gql';
import type { DocumentTypeDecoration } from '@graphql-typed-document-node/core';

// '@/gql' からexportされているuseFragmentはcomputedを挟まないので、reactivityが伝播しない。
// そのため、useFragmentをcomputedでラップしたuseComputedFragmentをauto importさせて使う。
export function useComputedFragment<TType>(
  _documentNode: DocumentTypeDecoration<TType, any>,
  fragmentType: RefOrGetterFunction<
    FragmentType<DocumentTypeDecoration<TType, any>>
  >
): ComputedRef<TType>;
export function useComputedFragment<TType>(
  _documentNode: DocumentTypeDecoration<TType, any>,
  fragmentType: RefOrGetterFunction<FragmentType<
    DocumentTypeDecoration<TType, any>
  > | null>
): ComputedRef<TType | null>;
export function useComputedFragment<TType>(
  _documentNode: DocumentTypeDecoration<TType, any>,
  fragmentType: RefOrGetterFunction<
    FragmentType<DocumentTypeDecoration<TType, any>> | undefined
  >
): ComputedRef<TType | undefined>;
export function useComputedFragment<TType>(
  _documentNode: DocumentTypeDecoration<TType, any>,
  fragmentType: RefOrGetterFunction<
    FragmentType<DocumentTypeDecoration<TType, any>> | null | undefined
  >
): ComputedRef<TType | null | undefined>;
export function useComputedFragment<TType>(
  _documentNode: DocumentTypeDecoration<TType, any>,
  fragmentType: RefOrGetterFunction<
    ReadonlyArray<FragmentType<DocumentTypeDecoration<TType, any>>>
  >
): ComputedRef<TType[]>;
export function useComputedFragment<TType>(
  _documentNode: DocumentTypeDecoration<TType, any>,
  fragmentType: RefOrGetterFunction<ReadonlyArray<
    FragmentType<DocumentTypeDecoration<TType, any>>
  > | null>
): ComputedRef<TType[] | null>;
export function useComputedFragment<TType>(
  _documentNode: DocumentTypeDecoration<TType, any>,
  fragmentType: RefOrGetterFunction<
    ReadonlyArray<FragmentType<DocumentTypeDecoration<TType, any>>> | undefined
  >
): ComputedRef<TType[] | undefined>;
export function useComputedFragment<TType>(
  _documentNode: DocumentTypeDecoration<TType, any>,
  fragmentType: RefOrGetterFunction<
    | ReadonlyArray<FragmentType<DocumentTypeDecoration<TType, any>>>
    | null
    | undefined
  >
): ComputedRef<TType[] | null | undefined>;
export function useComputedFragment<TType>(
  _documentNode: DocumentTypeDecoration<TType, any>,
  fragmentType: RefOrGetterFunction<
    | FragmentType<DocumentTypeDecoration<TType, any>>
    | ReadonlyArray<FragmentType<DocumentTypeDecoration<TType, any>>>
    | null
    | undefined
  >
): ComputedRef<TType | TType[] | null | undefined> {
  return computed<any>(() => {
    const fragmentTypeValue = toValue(fragmentType);
    return useFragment(_documentNode, fragmentTypeValue);
  });
}

// useFragmentをそのまま使うとcomputedが挟まれるのか分かりづらくなるので、
// computedを挟まないことが明示的にわかる関数をexportする。
export const getFragment = useFragment;
export { isFragmentReady } from '@/gql';
/* eslint-enable @typescript-eslint/no-explicit-any */
