import { useRuntimeConfig as _useRuntimeConfig } from '#app';
import { toServerEnv } from '~theme/libs/serverEnv';

// runtimeConfigの型を一部バリデーションしたいので、useRuntimeConfigをラップする
// nuxt.configのhookで、デフォルトのuseRuntimeConfigのauto-importを止めて、こちらをauto-importさせている
export const useRuntimeConfig = () => {
  const config = _useRuntimeConfig();

  return {
    ...config,
    public: {
      ...config.public,
      serverEnv: toServerEnv(config.public.serverEnv),
    },
  };
};
