import type { RouteLocationRaw } from '#vue-router';
import { graphql } from '@/gql';
import { useDocumentVisibility, useTimeoutPoll } from '@vueuse/core';
import { keyByFile } from 'theme/utils/keyByFile';
import { computed } from 'vue';
import type {
  UseNotificationsChatRoomFragment,
  UseNotificationsKartePlannerCommentFragment,
  UseNotificationsRecommendedPlannerFragment,
} from '~/gql/graphql';

export type Notification = {
  key: string;
  title: string;
  body: string;
  imageUrl?: string;
  location: RouteLocationRaw;
  datetime?: Date;
};

const POLLING_INTERVAL = 30 * 1000;

const ChatRoomFragment = graphql(`
  fragment UseNotificationsChatRoom on ChatRoom {
    id
    planner {
      id
      name
      avatar
    }
    latestMessage {
      isUnread
      createdAt
    }
  }
`);

const KartePlannerCommentFragment = graphql(`
  fragment UseNotificationsKartePlannerComment on KartePlannerComment {
    id
    planner {
      id
      name
      avatar
      isMatched
    }
    createdAt
  }
`);

const RecommendPlannerFragment = graphql(`
  fragment UseNotificationsRecommendedPlanner on Planner {
    id
    name
    avatar
    isMatched
  }
`);

const USE_NOTIFICATIONS_QUERY = graphql(`
  query UseNotificationsViewer {
    viewer2 {
      ... on ViewerQuerySuccessPayload {
        result {
          id
          currentKarte {
            id
            kartePlannerComments {
              ...UseNotificationsKartePlannerComment
            }
          }
          recommendedPlanner {
            ...UseNotificationsRecommendedPlanner
          }
          chatRooms {
            ...UseNotificationsChatRoom
          }
        }
      }
    }
  }
`);

const plannerAvatarUrl = (imgPath: ImgixImagePath | null | undefined) => {
  const { buildImgixURL } = useImgixURL();
  return buildImgixURL(
    (imgPath ?? '/avatar/0-default/default.png') as ImgixImagePath,
    {
      quality: 70,
      faceFit: true,
      mask: 'ellipse',
    }
  );
};

const notificationFromRecommendedPlanner = (
  planner: UseNotificationsRecommendedPlannerFragment
): Notification => ({
  key: `RecommendedPlanner:${planner.id}`,
  location: {
    path: `/planner/${planner.id}/matching`,
  },
  // TODO: AppNotification 使うのやめて component 側で PlannerThumbnail 使うとかでいい感じにする
  imageUrl: plannerAvatarUrl(planner.avatar),
  body: '新着メッセージがあります',
  title: planner.name,
  datetime: undefined,
});

const notificationFromChatRoom = (
  room: {
    latestMessage: NonNullable<
      UseNotificationsChatRoomFragment['latestMessage']
    >;
  } & UseNotificationsChatRoomFragment
): Notification => ({
  key: `LatestMessages:${room.id}:${room.latestMessage.createdAt}`,
  location: {
    path: `/chat/${room.id.toString()}`,
  },
  imageUrl: plannerAvatarUrl(room.planner.avatar),
  title: room.planner.name,
  body: '新着メッセージがあります',
  datetime: new Date(room.latestMessage.createdAt),
});

const notificationFromPlannerComment = (
  kpc: UseNotificationsKartePlannerCommentFragment
): Notification => ({
  key: `KartePlannerComment:${kpc.id}`,
  location: {
    path: `/planner/${kpc.planner.id}/matching`,
  },
  // TODO: AppNotification 使うのやめて component 側で PlannerThumbnail 使うとかでいい感じにする
  imageUrl: plannerAvatarUrl(kpc.planner.avatar),
  body: '新着メッセージがあります',
  title: kpc.planner.name,
  datetime: new Date(kpc.createdAt),
});

const useNotificationsQuery = () => {
  const { data, execute, refresh } = useLazyAsyncQuery(
    USE_NOTIFICATIONS_QUERY,
    {},
    { apollo: { fetchPolicy: 'no-cache' } }
  );

  const viewer = computed(() =>
    data.value?.viewer2.__typename === 'ViewerQuerySuccessPayload'
      ? data.value.viewer2.result
      : null
  );

  const kartePlannerComments = useComputedFragment(
    KartePlannerCommentFragment,
    () => viewer.value?.currentKarte?.kartePlannerComments ?? []
  );
  const kartePlannerCommentNotifications = computed(() =>
    kartePlannerComments.value.flatMap((kpc) =>
      kpc.planner.isMatched ? [] : [notificationFromPlannerComment(kpc)]
    )
  );

  const recommendedPlanner = useComputedFragment(
    RecommendPlannerFragment,
    () => viewer.value?.recommendedPlanner
  );
  const recommendedPlannerNotifications = computed(() =>
    recommendedPlanner.value == null
      ? []
      : [notificationFromRecommendedPlanner(recommendedPlanner.value)]
  );

  const chatRooms = useComputedFragment(
    ChatRoomFragment,
    () => viewer.value?.chatRooms ?? []
  );
  const latestMessageNotifications = computed(() =>
    chatRooms.value.flatMap((room) =>
      room.latestMessage != null && room.latestMessage.isUnread
        ? [
            notificationFromChatRoom({
              ...room,
              latestMessage: room.latestMessage,
            }),
          ]
        : []
    )
  );

  const notifications = computed(() =>
    [
      ...kartePlannerCommentNotifications.value,
      ...recommendedPlannerNotifications.value,
      ...latestMessageNotifications.value,
    ].sort(
      (n1: Notification, n2: Notification) =>
        (n2.datetime?.getTime() ?? 0) - (n1.datetime?.getTime() ?? 0)
    )
  );

  return {
    execute,
    refresh,
    notifications,
  };
};

const useNotificationEventBus = () => {
  return useEventBus<[Notification]>(keyByFile(import.meta, 'event'));
};
const useNotificationState = () => {
  return useState<Notification[]>(
    keyByFile(import.meta, 'notifications'),
    () => []
  );
};

export const useInitializeNotifications = () => {
  const { trigger } = useNotificationEventBus();
  const notificationsState = useNotificationState();

  const { notifications, execute, refresh } = useNotificationsQuery();

  watch(notifications, (newNotifications, oldNotifications) => {
    const oldNotificationKeys = oldNotifications.map((n) => n.key);
    newNotifications.forEach(
      (n) => !oldNotificationKeys.includes(n.key) && trigger(n)
    );
    notificationsState.value = newNotifications;
  });

  const { resume: startPolling, pause: stopPolling } = useTimeoutPoll(
    refresh,
    POLLING_INTERVAL,
    {
      immediate: false,
    }
  );

  const documentVisibility = useDocumentVisibility();
  useAuthenticationState().then(({ isAuthenticated }) =>
    watch(
      [isAuthenticated, documentVisibility],
      ([isAuthenticated, visibility]) =>
        isAuthenticated && visibility === 'visible'
          ? (async () => {
              await execute();
              startPolling();
            })()
          : stopPolling(),
      { immediate: true }
    )
  );
};

export const useNotifications = () => {
  const { subscribe, unsubscribe } = useNotificationEventBus();
  const notifications = useNotificationState();

  return {
    notifications: computed(() => notifications.value),
    subscribe,
    unsubscribe,
  };
};
