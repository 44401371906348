import InterBold from '~/assets/fonts/Inter-Bold.generated.woff2';
import InterRegular from '~/assets/fonts/Inter-Regular.generated.woff2';
import NotoSansBold from '~/assets/fonts/NotoSansJP-Bold.generated.woff2';
import NotoSansRegular from '~/assets/fonts/NotoSansJP-Regular.generated.woff2';

// MEMO: Blackは使用頻度が低いのでpreloadしないでおく
export default defineNuxtPlugin(() => {
  useServerHead({
    link: [
      {
        rel: 'preload',
        href: InterRegular,
        type: 'font/woff2',
        as: 'font',
        crossorigin: '',
      },
      {
        rel: 'preload',
        href: InterBold,
        type: 'font/woff2',
        as: 'font',
        crossorigin: '',
      },
      {
        rel: 'preload',
        href: NotoSansRegular,
        type: 'font/woff2',
        as: 'font',
        crossorigin: '',
      },
      {
        rel: 'preload',
        href: NotoSansBold,
        type: 'font/woff2',
        as: 'font',
        crossorigin: '',
      },
    ],
  });
});
