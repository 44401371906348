import type { LocationQuery, RouteLocationRaw } from 'vue-router';

export const injectQueryParamsToLocation = (
  location: RouteLocationRaw,
  targetQueryParams: LocationQuery
): RouteLocationRaw => {
  if (typeof location === 'string') {
    // location が string だった場合
    const fakeOrigin = 'https://okane-kenko.jp';
    const parsed = new URL(location, fakeOrigin);
    const newParams = new URLSearchParams();
    Object.entries(targetQueryParams).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => v && newParams.append(key, v));
        return;
      }
      if (value != null) newParams.append(key, value);
    });

    for (const [key, value] of parsed.searchParams.entries()) {
      newParams.set(key, value);
    }
    parsed.search = newParams.toString();
    return parsed.toString().replace(fakeOrigin, '');
  }
  // location が RouteLocationPathRaw | RouteLocationNamedRaw だった場合
  return {
    ...location,
    query: {
      ...targetQueryParams,
      ...location.query,
    },
  };
};
