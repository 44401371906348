import { targetQueryKeys } from '../../configs';
import type { LocationQuery } from 'vue-router';

export const filterInjectingQueryParams = (
  query: LocationQuery
): LocationQuery => {
  return Object.fromEntries(
    Object.entries(query).filter(([key]) => targetQueryKeys.includes(key))
  );
};
