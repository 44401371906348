
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index0XQJit8M41Meta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/account_activation_required/index.vue?macro=true";
import { default as calendar6jUKg4FOYNMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/booking_request/[bookingRequestId]/calendar.vue?macro=true";
import { default as _91file_key_93zNkWoIiZM9Meta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/chat_room_file/[file_key].vue?macro=true";
import { default as indexTj5oRm4yoWMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/chat/[roomId]/index.vue?macro=true";
import { default as user_upload9liaQOxOOoMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/chat/[roomId]/user_upload.vue?macro=true";
import { default as indexuObGO7pxseMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/chat/diagnosis/index.vue?macro=true";
import { default as _91uuid_93WQNgo4d9DyMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/chat/diagnosis/result/[uuid].vue?macro=true";
import { default as contact9d2pDzKwG3Meta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/contact.vue?macro=true";
import { default as testmodetLqSVRfjwYMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/dev/testmode.vue?macro=true";
import { default as _91uuid_93X414kMUuZnMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/diagnosis/result/[uuid].vue?macro=true";
import { default as resultmCkWr0EmisMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/diagnosis/result.vue?macro=true";
import { default as _91shareUuid_93eOCjvDsY5ZMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/diagnosis/share/[shareUuid].vue?macro=true";
import { default as faqFPF6QgKv0CMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/faq.vue?macro=true";
import { default as indexCbNrhA2Kd2Meta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/home/index.vue?macro=true";
import { default as indexsNxiRiPfmkMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/home/misc/index.vue?macro=true";
import { default as emailY7ggAtDZABMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/home/misc/settings/email.vue?macro=true";
import { default as indexWwQ8wGlOHcMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/home/misc/settings/index.vue?macro=true";
import { default as nickname05bV0ha9jtMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/home/misc/settings/nickname.vue?macro=true";
import { default as notificationsimsMRhZZMaMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/home/misc/settings/notifications.vue?macro=true";
import { default as passwordnWzK8L17VzMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/home/misc/settings/password.vue?macro=true";
import { default as settingsyfMuxGd7pqMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/home/misc/settings.vue?macro=true";
import { default as miscsFJ4Trh50IMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/home/misc.vue?macro=true";
import { default as _91_46_46_46path_93oEcf5zHsH8Meta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/image/[...path].vue?macro=true";
import { default as indexjEVU9S7MkPMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/index.vue?macro=true";
import { default as _91id_93Ypi7c6FJMmMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/interview/[id].vue?macro=true";
import { default as indexcPBCDbWbR8Meta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/interview/index.vue?macro=true";
import { default as callbackFXUgwlyBJxMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/line/callback.vue?macro=true";
import { default as loginLCQh6AW3rNMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/login.vue?macro=true";
import { default as indexvfneWHBM45Meta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/lp/ad4a/index.vue?macro=true";
import { default as fiduciarydutyqeaZJdgkEkMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/lp/fiduciaryduty.vue?macro=true";
import { default as indexoJaZdqqeTSMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/lp/vitality_smart_smcc_line/index.vue?macro=true";
import { default as _91token_936wSpo4AL2xMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/mail_activation/[token].vue?macro=true";
import { default as chat_listms60eDWFkSMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/my/chat_list.vue?macro=true";
import { default as indexw44oEEhK5FMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/my/index.vue?macro=true";
import { default as messagesPV66Va3souMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/my/messages.vue?macro=true";
import { default as overviewnJIAjTxnpiMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/my/overview.vue?macro=true";
import { default as myiI62rrLEtZMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/my.vue?macro=true";
import { default as indexKZRq08w03aMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/400f_2/index.vue?macro=true";
import { default as indexf93m2k3MuCMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/400f/index.vue?macro=true";
import { default as gettingstartedYgwQ52L8IKMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/famipay/gettingstarted.vue?macro=true";
import { default as index0wkWLHpdGDMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/famipay/index.vue?macro=true";
import { default as index4Qk5IryxI9Meta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/hanasaku/index.vue?macro=true";
import { default as indexQKKJWVLui5Meta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/hokennomadoguchi_tsuhan/index.vue?macro=true";
import { default as indexB2Pq9kmttzMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/hokennomadoguchi/index.vue?macro=true";
import { default as indexS1GUniKg0jMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/kiraboshi/index.vue?macro=true";
import { default as indexmclXFYyX13Meta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/moneyforward/index.vue?macro=true";
import { default as index5X1XtWecTiMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/ninpath/index.vue?macro=true";
import { default as policy0sztjKs1PvMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/ninpath/policy.vue?macro=true";
import { default as termsMjJuoGUk7tMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/ninpath/terms.vue?macro=true";
import { default as indexm9k90nIW7oMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/ponta/index.vue?macro=true";
import { default as _91id_93JoLSZurcDcMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/ponta/questionnaire/[id].vue?macro=true";
import { default as indexx3tmmlXaQcMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/ponta/questionnaire/index.vue?macro=true";
import { default as indexVNEmAVZO7HMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/rakuten_inhouseadvisor/index.vue?macro=true";
import { default as indexKzYKdzud0XMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/rakuten/index.vue?macro=true";
import { default as indexcKfUbyROBDMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/rakuten/topics/inheritance/index.vue?macro=true";
import { default as indexKnrjSJSWyTMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/rakuten/topics/life_planning/index.vue?macro=true";
import { default as indexlg2qwztgtsMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/rakuten/topics/nisa/index.vue?macro=true";
import { default as indexHhz6GuTeFrMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/rakuten/topics/retirement/index.vue?macro=true";
import { default as indexeISYvgTth4Meta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/rakuten/topics/securities/index.vue?macro=true";
import { default as indexye6k8brJ7gMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/sbi/index.vue?macro=true";
import { default as chatKCSvLzw6m9Meta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/smcc/chat.vue?macro=true";
import { default as indextD6qZJrAOvMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/smcc/index.vue?macro=true";
import { default as indexKIH6A4ux2pMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/yahoo/index.vue?macro=true";
import { default as indexnr4ypvvtJNMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/zjk/index.vue?macro=true";
import { default as chatB1sk47naB3Meta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/planner/[id]/chat.vue?macro=true";
import { default as indexYw1tiivhhSMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/planner/[id]/index.vue?macro=true";
import { default as matchingKZ0cj7h5kiMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/planner/[id]/matching.vue?macro=true";
import { default as useMatching9BUn73tuLnMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/planner/[id]/useMatching.ts?macro=true";
import { default as antisocial057c6X5zvCMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/policies/antisocial.vue?macro=true";
import { default as attention0RMDuaPlYaMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/policies/attention.vue?macro=true";
import { default as cookiepolicycmpJZrvNAURhuMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/policies/cookiepolicycmp.vue?macro=true";
import { default as dispute82XuOf5Ms2Meta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/policies/dispute.vue?macro=true";
import { default as entrusterx7fOwTKt65Meta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/policies/entruster.vue?macro=true";
import { default as fiduciarydutyd98zzr7Vf9Meta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/policies/fiduciaryduty.vue?macro=true";
import { default as intermediaryTO0C8GqyozMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/policies/intermediary.vue?macro=true";
import { default as privacyquF26Vz2tvMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/policies/privacy.vue?macro=true";
import { default as solicitation2pegMb6E4oMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/policies/solicitation.vue?macro=true";
import { default as supported_versionskTVRGkl7qcMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/policies/supported_versions.vue?macro=true";
import { default as tosaOsyqQpAmPMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/policies/tos.vue?macro=true";
import { default as assetoj14VEWJoTMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/products/asset.vue?macro=true";
import { default as childbirthq0u87xBq1TMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/products/childbirth.vue?macro=true";
import { default as education_45fundyqIch53kYAMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/products/education-fund.vue?macro=true";
import { default as finance_45diagnosisoeYb8lYARRMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/products/finance-diagnosis.vue?macro=true";
import { default as idecooefh94wuUgMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/products/ideco.vue?macro=true";
import { default as insuranceqLa63P1kLjMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/products/insurance.vue?macro=true";
import { default as investmenttrustxbT5rDULFxMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/products/investmenttrust.vue?macro=true";
import { default as lifeplanl1r4TnvWX0Meta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/products/lifeplan.vue?macro=true";
import { default as marriage_45costA9kAxXx0aBMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/products/marriage-cost.vue?macro=true";
import { default as mortgageR5Rfu0rOcaMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/products/mortgage.vue?macro=true";
import { default as nisaXnrme5NkI6Meta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/products/nisa.vue?macro=true";
import { default as retirement5zu00R4iIBMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/products/retirement.vue?macro=true";
import { default as trainingsxNxB61CqUMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/products/training.vue?macro=true";
import { default as productsHnAuxmtyPJMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/products.vue?macro=true";
import { default as _91id_93t7DlgPoMuwMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/questionnaire/[id].vue?macro=true";
import { default as indexIqj9Xhhob7Meta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/questionnaire/index.vue?macro=true";
import { default as indexq1EtKVV0ihMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/redirect_external/index.vue?macro=true";
import { default as emailrzcFyJTzRVMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/register/email.vue?macro=true";
import { default as indexTeeWjKmBo1Meta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/register/index.vue?macro=true";
import { default as start0RYHBN5CHSMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/reset_password/start.vue?macro=true";
import { default as _91token_931NYIYfZlxBMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/reset_password/verify/[token].vue?macro=true";
import { default as entryZeuRkHwyDXMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/seminar/[id]/entry.vue?macro=true";
import { default as index5kLpEp9Qt4Meta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/seminar/[id]/index.vue?macro=true";
import { default as index6NHZWGrkyDMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/seminar/index.vue?macro=true";
import { default as insurance_product_consultation_requestKWrJXaLuzDMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/send_message/insurance_product_consultation_request.vue?macro=true";
import { default as insurance_product_document_request3sDtKgrDsTMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/send_message/insurance_product_document_request.vue?macro=true";
import { default as sessionLYeQP1XoZdMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/session.vue?macro=true";
import { default as emailJzodrUlAQCMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/settings/email.vue?macro=true";
import { default as indexXAGdB70n1pMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/settings/index.vue?macro=true";
import { default as nicknamevlaJXcoBbaMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/settings/nickname.vue?macro=true";
import { default as notificationM18UW8hFZSMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/settings/notification.vue?macro=true";
import { default as passwordcCgmysmzN4Meta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/settings/password.vue?macro=true";
import { default as settings7KhKP3r9XpMeta } from "/home/runner/_work/shadow/shadow/packages/app/src/pages/settings.vue?macro=true";
import { default as component_45stubeBQtMjj89CMeta } from "/home/runner/_work/shadow/shadow/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.9_db0@0.2.1_eslint@9.21.0_jiti@2.4._1402de71b07b515abb6f0c34345cdb6e/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubeBQtMjj89C } from "/home/runner/_work/shadow/shadow/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.9_db0@0.2.1_eslint@9.21.0_jiti@2.4._1402de71b07b515abb6f0c34345cdb6e/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account_activation_required",
    path: "/account_activation_required",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/account_activation_required/index.vue")
  },
  {
    name: "booking_request-bookingRequestId-calendar",
    path: "/booking_request/:bookingRequestId()/calendar",
    meta: calendar6jUKg4FOYNMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/booking_request/[bookingRequestId]/calendar.vue")
  },
  {
    name: "chat_room_file-file_key",
    path: "/chat_room_file/:file_key()",
    meta: _91file_key_93zNkWoIiZM9Meta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/chat_room_file/[file_key].vue")
  },
  {
    name: "chat-roomId",
    path: "/chat/:roomId()",
    meta: indexTj5oRm4yoWMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/chat/[roomId]/index.vue")
  },
  {
    name: "chat-roomId-user_upload",
    path: "/chat/:roomId()/user_upload",
    meta: user_upload9liaQOxOOoMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/chat/[roomId]/user_upload.vue")
  },
  {
    name: "chat-diagnosis",
    path: "/chat/diagnosis",
    meta: indexuObGO7pxseMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/chat/diagnosis/index.vue")
  },
  {
    name: "chat-diagnosis-result-uuid",
    path: "/chat/diagnosis/result/:uuid()",
    meta: _91uuid_93WQNgo4d9DyMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/chat/diagnosis/result/[uuid].vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/contact.vue")
  },
  {
    name: "dev-testmode",
    path: "/dev/testmode",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/dev/testmode.vue")
  },
  {
    name: "diagnosis-result",
    path: "/diagnosis/result",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/diagnosis/result.vue"),
    children: [
  {
    name: "diagnosis-result-uuid",
    path: ":uuid()",
    meta: _91uuid_93X414kMUuZnMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/diagnosis/result/[uuid].vue")
  }
]
  },
  {
    name: "diagnosis-share-shareUuid",
    path: "/diagnosis/share/:shareUuid()",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/diagnosis/share/[shareUuid].vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/faq.vue")
  },
  {
    name: "home",
    path: "/home",
    meta: indexCbNrhA2Kd2Meta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/home/index.vue")
  },
  {
    name: miscsFJ4Trh50IMeta?.name,
    path: "/home/misc",
    meta: miscsFJ4Trh50IMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/home/misc.vue"),
    children: [
  {
    name: "home-misc",
    path: "",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/home/misc/index.vue")
  },
  {
    name: settingsyfMuxGd7pqMeta?.name,
    path: "settings",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/home/misc/settings.vue"),
    children: [
  {
    name: "home-misc-settings-email",
    path: "email",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/home/misc/settings/email.vue")
  },
  {
    name: "home-misc-settings",
    path: "",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/home/misc/settings/index.vue")
  },
  {
    name: "home-misc-settings-nickname",
    path: "nickname",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/home/misc/settings/nickname.vue")
  },
  {
    name: "home-misc-settings-notifications",
    path: "notifications",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/home/misc/settings/notifications.vue")
  },
  {
    name: "home-misc-settings-password",
    path: "password",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/home/misc/settings/password.vue")
  }
]
  }
]
  },
  {
    name: "image-path",
    path: "/image/:path(.*)*",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/image/[...path].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexjEVU9S7MkPMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/index.vue")
  },
  {
    name: "interview-id",
    path: "/interview/:id()",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/interview/[id].vue")
  },
  {
    name: "interview",
    path: "/interview",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/interview/index.vue")
  },
  {
    name: "line-callback",
    path: "/line/callback",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/line/callback.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/login.vue")
  },
  {
    name: "lp-ad4a",
    path: "/lp/ad4a",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/lp/ad4a/index.vue")
  },
  {
    name: "lp-fiduciaryduty",
    path: "/lp/fiduciaryduty",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/lp/fiduciaryduty.vue")
  },
  {
    name: "lp-vitality_smart_smcc_line",
    path: "/lp/vitality_smart_smcc_line",
    meta: indexoJaZdqqeTSMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/lp/vitality_smart_smcc_line/index.vue")
  },
  {
    name: "mail_activation-token",
    path: "/mail_activation/:token()",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/mail_activation/[token].vue")
  },
  {
    name: myiI62rrLEtZMeta?.name,
    path: "/my",
    meta: myiI62rrLEtZMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/my.vue"),
    children: [
  {
    name: "my-chat_list",
    path: "chat_list",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/my/chat_list.vue")
  },
  {
    name: "my",
    path: "",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/my/index.vue")
  },
  {
    name: "my-messages",
    path: "messages",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/my/messages.vue")
  },
  {
    name: "my-overview",
    path: "overview",
    meta: overviewnJIAjTxnpiMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/my/overview.vue")
  }
]
  },
  {
    name: "partner-400f_2",
    path: "/partner/400f_2",
    meta: indexKZRq08w03aMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/400f_2/index.vue")
  },
  {
    name: "partner-400f",
    path: "/partner/400f",
    meta: indexf93m2k3MuCMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/400f/index.vue")
  },
  {
    name: "partner-famipay-gettingstarted",
    path: "/partner/famipay/gettingstarted",
    meta: gettingstartedYgwQ52L8IKMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/famipay/gettingstarted.vue")
  },
  {
    name: "partner-famipay",
    path: "/partner/famipay",
    meta: index0wkWLHpdGDMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/famipay/index.vue")
  },
  {
    name: "partner-hanasaku",
    path: "/partner/hanasaku",
    meta: index4Qk5IryxI9Meta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/hanasaku/index.vue")
  },
  {
    name: "partner-hokennomadoguchi_tsuhan",
    path: "/partner/hokennomadoguchi_tsuhan",
    meta: indexQKKJWVLui5Meta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/hokennomadoguchi_tsuhan/index.vue")
  },
  {
    name: "partner-hokennomadoguchi",
    path: "/partner/hokennomadoguchi",
    meta: indexB2Pq9kmttzMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/hokennomadoguchi/index.vue")
  },
  {
    name: "partner-kiraboshi",
    path: "/partner/kiraboshi",
    meta: indexS1GUniKg0jMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/kiraboshi/index.vue")
  },
  {
    name: "partner-moneyforward",
    path: "/partner/moneyforward",
    meta: indexmclXFYyX13Meta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/moneyforward/index.vue")
  },
  {
    name: "partner-ninpath",
    path: "/partner/ninpath",
    meta: index5X1XtWecTiMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/ninpath/index.vue")
  },
  {
    name: "partner-ninpath-policy",
    path: "/partner/ninpath/policy",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/ninpath/policy.vue")
  },
  {
    name: "partner-ninpath-terms",
    path: "/partner/ninpath/terms",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/ninpath/terms.vue")
  },
  {
    name: "partner-ponta",
    path: "/partner/ponta",
    meta: indexm9k90nIW7oMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/ponta/index.vue")
  },
  {
    name: "partner-ponta-questionnaire-id",
    path: "/partner/ponta/questionnaire/:id()",
    meta: _91id_93JoLSZurcDcMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/ponta/questionnaire/[id].vue")
  },
  {
    name: "partner-ponta-questionnaire",
    path: "/partner/ponta/questionnaire",
    meta: indexx3tmmlXaQcMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/ponta/questionnaire/index.vue")
  },
  {
    name: "partner-rakuten_inhouseadvisor",
    path: "/partner/rakuten_inhouseadvisor",
    meta: indexVNEmAVZO7HMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/rakuten_inhouseadvisor/index.vue")
  },
  {
    name: "partner-rakuten",
    path: "/partner/rakuten",
    meta: indexKzYKdzud0XMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/rakuten/index.vue")
  },
  {
    name: "partner-rakuten-topics-inheritance",
    path: "/partner/rakuten/topics/inheritance",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/rakuten/topics/inheritance/index.vue")
  },
  {
    name: "partner-rakuten-topics-life_planning",
    path: "/partner/rakuten/topics/life_planning",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/rakuten/topics/life_planning/index.vue")
  },
  {
    name: "partner-rakuten-topics-nisa",
    path: "/partner/rakuten/topics/nisa",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/rakuten/topics/nisa/index.vue")
  },
  {
    name: "partner-rakuten-topics-retirement",
    path: "/partner/rakuten/topics/retirement",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/rakuten/topics/retirement/index.vue")
  },
  {
    name: "partner-rakuten-topics-securities",
    path: "/partner/rakuten/topics/securities",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/rakuten/topics/securities/index.vue")
  },
  {
    name: "partner-sbi",
    path: "/partner/sbi",
    meta: indexye6k8brJ7gMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/sbi/index.vue")
  },
  {
    name: "partner-smcc-chat",
    path: "/partner/smcc/chat",
    meta: chatKCSvLzw6m9Meta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/smcc/chat.vue")
  },
  {
    name: "partner-smcc",
    path: "/partner/smcc",
    meta: indextD6qZJrAOvMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/smcc/index.vue")
  },
  {
    name: "partner-yahoo",
    path: "/partner/yahoo",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/yahoo/index.vue")
  },
  {
    name: "partner-zjk",
    path: "/partner/zjk",
    meta: indexnr4ypvvtJNMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/partner/zjk/index.vue")
  },
  {
    name: "planner-id-chat",
    path: "/planner/:id()/chat",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/planner/[id]/chat.vue")
  },
  {
    name: "planner-id",
    path: "/planner/:id()",
    meta: indexYw1tiivhhSMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/planner/[id]/index.vue")
  },
  {
    name: "planner-id-matching",
    path: "/planner/:id()/matching",
    meta: matchingKZ0cj7h5kiMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/planner/[id]/matching.vue")
  },
  {
    name: "planner-id-useMatching",
    path: "/planner/:id()/useMatching",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/planner/[id]/useMatching.ts")
  },
  {
    name: "policies-antisocial",
    path: "/policies/antisocial",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/policies/antisocial.vue")
  },
  {
    name: "policies-attention",
    path: "/policies/attention",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/policies/attention.vue")
  },
  {
    name: "policies-cookiepolicycmp",
    path: "/policies/cookiepolicycmp",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/policies/cookiepolicycmp.vue")
  },
  {
    name: "policies-dispute",
    path: "/policies/dispute",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/policies/dispute.vue")
  },
  {
    name: "policies-entruster",
    path: "/policies/entruster",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/policies/entruster.vue")
  },
  {
    name: "policies-fiduciaryduty",
    path: "/policies/fiduciaryduty",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/policies/fiduciaryduty.vue")
  },
  {
    name: "policies-intermediary",
    path: "/policies/intermediary",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/policies/intermediary.vue")
  },
  {
    name: "policies-privacy",
    path: "/policies/privacy",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/policies/privacy.vue")
  },
  {
    name: "policies-solicitation",
    path: "/policies/solicitation",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/policies/solicitation.vue")
  },
  {
    name: "policies-supported_versions",
    path: "/policies/supported_versions",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/policies/supported_versions.vue")
  },
  {
    name: "policies-tos",
    path: "/policies/tos",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/policies/tos.vue")
  },
  {
    name: "products",
    path: "/products",
    meta: productsHnAuxmtyPJMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/products.vue"),
    children: [
  {
    name: "products-asset",
    path: "asset",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/products/asset.vue")
  },
  {
    name: "products-childbirth",
    path: "childbirth",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/products/childbirth.vue")
  },
  {
    name: "products-education-fund",
    path: "education-fund",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/products/education-fund.vue")
  },
  {
    name: "products-finance-diagnosis",
    path: "finance-diagnosis",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/products/finance-diagnosis.vue")
  },
  {
    name: "products-ideco",
    path: "ideco",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/products/ideco.vue")
  },
  {
    name: "products-insurance",
    path: "insurance",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/products/insurance.vue")
  },
  {
    name: "products-investmenttrust",
    path: "investmenttrust",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/products/investmenttrust.vue")
  },
  {
    name: "products-lifeplan",
    path: "lifeplan",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/products/lifeplan.vue")
  },
  {
    name: "products-marriage-cost",
    path: "marriage-cost",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/products/marriage-cost.vue")
  },
  {
    name: "products-mortgage",
    path: "mortgage",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/products/mortgage.vue")
  },
  {
    name: "products-nisa",
    path: "nisa",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/products/nisa.vue")
  },
  {
    name: "products-retirement",
    path: "retirement",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/products/retirement.vue")
  },
  {
    name: "products-training",
    path: "training",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/products/training.vue")
  }
]
  },
  {
    name: "questionnaire-id",
    path: "/questionnaire/:id()",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/questionnaire/[id].vue")
  },
  {
    name: "questionnaire",
    path: "/questionnaire",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/questionnaire/index.vue")
  },
  {
    name: "redirect_external",
    path: "/redirect_external",
    meta: indexq1EtKVV0ihMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/redirect_external/index.vue")
  },
  {
    name: "register-email",
    path: "/register/email",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/register/email.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: indexTeeWjKmBo1Meta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/register/index.vue")
  },
  {
    name: "reset_password-start",
    path: "/reset_password/start",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/reset_password/start.vue")
  },
  {
    name: "reset_password-verify-token",
    path: "/reset_password/verify/:token()",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/reset_password/verify/[token].vue")
  },
  {
    name: "seminar-id-entry",
    path: "/seminar/:id()/entry",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/seminar/[id]/entry.vue")
  },
  {
    name: "seminar-id",
    path: "/seminar/:id()",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/seminar/[id]/index.vue")
  },
  {
    name: "seminar",
    path: "/seminar",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/seminar/index.vue")
  },
  {
    name: "send_message-insurance_product_consultation_request",
    path: "/send_message/insurance_product_consultation_request",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/send_message/insurance_product_consultation_request.vue")
  },
  {
    name: "send_message-insurance_product_document_request",
    path: "/send_message/insurance_product_document_request",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/send_message/insurance_product_document_request.vue")
  },
  {
    name: "session",
    path: "/session",
    meta: sessionLYeQP1XoZdMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/session.vue")
  },
  {
    name: settings7KhKP3r9XpMeta?.name,
    path: "/settings",
    meta: settings7KhKP3r9XpMeta || {},
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/settings.vue"),
    children: [
  {
    name: "settings-email",
    path: "email",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/settings/email.vue")
  },
  {
    name: "settings",
    path: "",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/settings/index.vue")
  },
  {
    name: "settings-nickname",
    path: "nickname",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/settings/nickname.vue")
  },
  {
    name: "settings-notification",
    path: "notification",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/settings/notification.vue")
  },
  {
    name: "settings-password",
    path: "password",
    component: () => import("/home/runner/_work/shadow/shadow/packages/app/src/pages/settings/password.vue")
  }
]
  },
  {
    name: component_45stubeBQtMjj89CMeta?.name,
    path: "/lp/fp/:pathMatch(.*)",
    component: component_45stubeBQtMjj89C
  }
]