import { HotelTokenManager } from './hotel';

const manager = lazy(() => {
  if (import.meta.server)
    throw Error('useHotelTokenManager does not work on the server-side');
  return new HotelTokenManager();
});

export const useHotelTokenManager = () => ({
  tokenManager: manager.value,
  then: <Result>(
    onInitialized: (v: { tokenManager: HotelTokenManager }) => Result
  ) => manager.value.then(() => onInitialized({ tokenManager: manager.value })),
});
