<script setup lang="ts">
/**
 * SectionContainer
 *
 * ページのコンテンツを中央に寄せて、画面幅に応じてpaddingを調整するコンポーネント。
 * デフォルトでは
 *   mobile: small
 *   tablet: medium
 *   desktop: large
 * で切り替わるが、maxVariantを指定することで、それより大きい画面幅に広がらないようにできる。
 *
 * ex: maxVariant medeiumの場合、desktopの画面幅でもmediumのwidth, paddingで止まる。
 */
type Variant = 'small' | 'medium' | 'large';
const props = defineProps<{
  maxVariant?: Variant;
  // slotのコンテンツのために定義したクラスはこっちに入れると効く
  innerClass?: string | string[];
}>();

const styles = useCssModule();

const maxVariantModifierClasses = computed(() => {
  return [styles[`wrapper--max-${props.maxVariant ?? 'large'}`]];
});
</script>

<template>
  <div :class="[$style.wrapper, ...maxVariantModifierClasses]">
    <div :class="$style.wrapper__body">
      <div :class="innerClass"><slot /></div>
    </div>
  </div>
</template>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-inline: var(--inline-padding-size);

  // figma:
  //   https://www.figma.com/file/Su2owtCMn11WJIYOcvlMHT/Design-System?type=design&node-id=841%3A2587&mode=design&t=tovT2R7SU9r9wFhF-1
  @mixin small {
    --inline-padding-size: #{$gutter-width-small};
    --max-width-size: #{$content-width-small};
  }

  @mixin medium {
    --inline-padding-size: #{$gutter-width-medium};
    --max-width-size: #{$content-width-medium};
  }

  @mixin large {
    --inline-padding-size: #{$gutter-width-medium};
    --max-width-size: #{$content-width-large};
  }

  &--max-small {
    @include small;
  }

  &--max-medium {
    @include mobile-only {
      @include small;
    }

    @include tablet-only {
      @include medium;
    }

    @include desktop-only {
      @include medium;
    }
  }

  &--max-large {
    @include mobile-only {
      @include small;
    }

    @include tablet-only {
      @include medium;
    }

    @include desktop-only {
      @include large;
    }
  }

  &__body {
    width: 100%;
    max-width: var(--max-width-size);
    margin: 0 auto 0;
  }
}
</style>
