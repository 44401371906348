export default defineNuxtPlugin((nuxt) => {
  const {
    public: { serverEnv },
  } = useRuntimeConfig();
  const isProduction = computed(() => {
    return serverEnv === 'production';
  });

  nuxt.vueApp.directive('test', {
    created(el, binding) {
      if (!(el instanceof HTMLElement)) {
        return;
      }
      if (!isProduction.value) {
        el.setAttribute('data-test', binding.value);
      }
    },
  });
});
