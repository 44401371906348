import { filterInjectingQueryParams } from '../utils/filterInjectingQueryParams';
import deepEqual from 'fast-deep-equal';
import { type RouteMiddleware, defineNuxtRouteMiddleware } from 'nuxt/app';

export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.server || deepEqual(to.query, from.query)) return; // 遷移前後のクエリパラメータが完全に一致する時は無視

  const injectingQueryParams = filterInjectingQueryParams(from.query);
  if (Object.keys(injectingQueryParams).length === 0) return; // 注入するクエリパラメータがない場合は無視

  const isAllKeyInjected = Object.keys(injectingQueryParams).every(
    (injectingQueryKey) => injectingQueryKey in to.query
  );
  if (isAllKeyInjected) return; // すでに全てのクエリパラメータが注入されている場合は無視

  const destination: ReturnType<RouteMiddleware> = {
    ...to,
    query: {
      ...to.query,
      ...injectingQueryParams,
    },
  };

  return destination;
});
