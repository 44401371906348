<script lang="ts">
export const sizes = ['small', 'medium', 'large'] as const;
export const variants = [
  'primary', // accent-fill
  'secondary', // accent-outline accent-label
  'base', // low-emphasis-outline
  'plain', // accent-label
  'conversion', // complementary-fill
  'success', // success-fill
  'caution', // error-fill
  'line', // LINE brand color
] as const;

export type Size = ValueOf<typeof sizes>;
export type Variant = ValueOf<typeof variants>;
</script>

<script lang="ts" setup>
import { useSlots } from 'vue';

const props = withDefaults(
  defineProps<{
    size?: Size;
    variant?: Variant;
    disabled?: boolean;
  }>(),
  {
    size: 'medium',
    variant: 'secondary',
    disabled: false,
  }
);

const slots = useSlots();
const FirstChild = () => {
  const child = slots.default?.()[0];
  if (!child) {
    throw new Error('ButtonFlatStyle.vue needs a child element.');
  }
  return child;
};
</script>

<template>
  <FirstChild
    :class="[
      $style.button,
      $style[`button--${props.variant}`],
      $style[`button--${props.size}`],
      {
        [$style['button--enabled']]: !props.disabled,
        [$style['button--disabled']]: props.disabled,
      },
    ]"
  />
</template>

<style lang="scss" module>
.button {
  $root: &;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border: 1px solid transparent;
  border-radius: 8px;

  &:hover {
    text-decoration: none;
  }

  &:focus-visible {
    outline-offset: 4px;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &--disabled {
    cursor: not-allowed;
  }

  /* padding 外から仕込みたいケース結構あるので詳細度下げとく  */
  // Size
  :where(&--small) {
    font-size: 12px;
    padding: 4px 16px;
  }

  :where(&--medium) {
    font-size: 14px;
    padding: 8px 40px;
  }

  :where(&--large) {
    font-size: 16px;
    padding: 12px 52px;
  }

  // Variant
  &--primary {
    color: $color-text-and-object-high-emphasis-inverse;
    background-color: $color-surface-accent-primary;

    &#{$root}--enabled {
      &:hover {
        background-color: rgba($color-surface-accent-primary, 0.9);
      }
    }

    &#{$root}--disabled {
      color: $color-text-and-object-disable;
      background-color: $color-surface-tertiary;
    }
  }

  &--secondary {
    color: $color-text-and-object-accent-primary;
    background-color: $color-surface-primary;
    border: 1px solid $color-border-accent-primary;

    &#{$root}--enabled {
      &:hover {
        opacity: 0.8;
      }
    }

    &#{$root}--disabled {
      color: $color-text-and-object-disable;
      background-color: $color-surface-primary;
      border: 1px solid $color-border-low-emphasis;
    }
  }

  &--base {
    color: $color-text-and-object-high-emphasis;
    background-color: $color-surface-primary;
    border: 1px solid $color-border-low-emphasis;

    &#{$root}--enabled {
      &:hover {
        background-color: $color-surface-secondary;
      }
    }

    &#{$root}--disabled {
      color: $color-text-and-object-disable;
      background-color: $color-surface-primary;
      border: 1px solid $color-border-low-emphasis;
    }
  }

  &--plain {
    color: $color-text-and-object-accent-primary;
    background-color: $color-surface-primary;

    &#{$root}--enabled {
      &:hover {
        background-color: $color-surface-accent-primary-light;
      }
    }

    &#{$root}--disabled {
      color: $color-text-and-object-disable;
      background-color: $color-surface-primary;
    }
  }

  &--conversion {
    color: $color-text-and-object-high-emphasis-inverse;
    background-color: $color-surface-accent-orange;

    &#{$root}--enabled {
      &:hover {
        opacity: 0.9;
      }
    }

    &#{$root}--disabled {
      color: $color-text-and-object-disable;
      background-color: $color-surface-tertiary;
    }
  }

  &--success {
    color: $color-success-object;
    background-color: $color-success-surface;

    &#{$root}--enabled {
      &:hover {
        opacity: 0.9;
      }
    }

    &#{$root}--disabled {
      color: $color-text-and-object-disable;
      background-color: $color-surface-tertiary;
    }
  }

  &--caution {
    color: $color-caution-object;
    background-color: $color-caution-surface;

    &#{$root}--enabled {
      &:hover {
        opacity: 0.9;
      }
    }

    &#{$root}--disabled {
      color: $color-text-and-object-disable;
      background-color: $color-surface-tertiary;
    }
  }

  &--line {
    color: $color-text-and-object-high-emphasis-inverse;
    background-color: $color-third-party-line;

    &#{$root}--enabled {
      &:hover {
        opacity: 0.9;
      }
    }

    &#{$root}--disabled {
      color: $color-text-and-object-disable;
      background-color: $color-surface-tertiary;
    }
  }
}
</style>
