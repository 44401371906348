import { filterInjectingQueryParams } from '../utils/filterInjectingQueryParams';
import { computed } from 'vue';
import { type LocationQuery, useRoute } from 'vue-router';

export const useStickyQuery = () => {
  const route = useRoute();
  const targetQueries = computed<LocationQuery>(() => {
    return filterInjectingQueryParams(route.query);
  });

  return { targetQueries };
};
