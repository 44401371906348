export const useConstants = () => {
  const USER_STATS = {
    countIn10K: 100,
    referenceDate: '2024年7月1日',
  };

  const CORPORATION = {
    name: '株式会社400F',
    webPage: 'https://400f.jp',
    founder: '中村 仁',
    foundingDate: '2017-11-1T00:00:00+09:00',
    telephone: '03-6810-8540',
    image: {
      url: 'https://400f.jp/apple-touch-icon.png',
    },

    address: {
      streetAddress: '日本橋兜町9-1 兜町第2平和ビル FinGATE BLOOM 4F',
      addressLocality: '中央区',
      addressRegion: '東京都',
      addressCountry: 'JP',
      postalCode: '103-0026',
      telephone: '03-6810-8540',
    },
  };

  const WEB = {
    homePage: 'https://okane-kenko.jp',
    description: `累計ユーザー数${USER_STATS.countIn10K}万人突破／オカネコは、国内最大級の家計診断・相談サービス。サービス利用の満足度98%以上。お金のことならすべてオカネコにお任せ。TVCMも好評放映中！`,
  };

  return {
    USER_STATS,
    CORPORATION,
    WEB,
  };
};
