<script lang="ts" setup>
import type { NuxtError } from 'nuxt/app';
import ErrorTemplate from 'theme/components/templates/ErrorTemplate/ErrorTemplate.vue';

const props = defineProps<{
  error: NuxtError;
}>();

const {
  public: { serverEnv },
} = useRuntimeConfig();

const message = computed((): string =>
  props.error.statusCode === 404
    ? 'ページが見つかりませんでした'
    : 'エラーが発生しました'
);

const status = computed(() => props.error.statusCode ?? 500);

const additionalMessage = computed(() =>
  props.error.statusCode === 404
    ? ''
    : '恐れ入りますが、時間が経ってから再度お試しください。'
);

const route = useRoute();
const { logger } = useLogger();
if (props.error.statusCode === 404) {
  logger.value?.page.notFound({
    to: {
      path: route.fullPath,
    },
  });
}

useAppMeta({
  title: message.value,
});
</script>

<template>
  <NuxtLayout>
    <template v-if="serverEnv === 'local' || serverEnv === 'alpha'">
      <pre>{{ error.message }}</pre>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <pre v-html="error.stack" />
    </template>
    <ErrorTemplate
      :message="message"
      :status="status"
      :additional-message="additionalMessage"
    />
  </NuxtLayout>
</template>
