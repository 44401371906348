// モーダル開いてるときにスクロールを止めるやつ
import { useEntireModal } from 'theme/composables/useModal/useEntireModalState';

export default defineNuxtPlugin(() => {
  const { isSomeModalOpened } = useEntireModal();

  watch(
    isSomeModalOpened,
    (isSomeModalOpened) => {
      if (isSomeModalOpened) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    },
    {
      immediate: true,
    }
  );
});
