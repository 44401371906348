export class Observable<T> {
  private value_: T;

  get value(): T {
    return this.value_;
  }
  set value(v: T) {
    const old = this.value_;
    this.value_ = v;
    this.listener(v, old);
  }

  private listener: (new_: T, old: T) => unknown;

  constructor(initialValue: T, listener: (new_: T, old: T) => unknown) {
    this.value_ = initialValue;
    this.listener = listener;
  }
}
