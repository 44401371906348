export const CHANNEL_ID_MAP = {
  OKANEKO: 1,
  RAKUTEN: 2,
  YAHOO: 3,
  SBI: 4,
  MINKABU: 5,
  PONTA: 6,
  OKB: 7,
  KIRABOSHI: 8,
  FOUR_HUNDRED_F: 9,
  MET_LIFE: 10,
  HANASAKU: 11,
  HOKEN_NO_MADOGUCHI: 12,
  SMCC: 13,
  FAMIPAY: 14,
  RAKUTEN_IN_HOUSE_ADVISOR: 15,
  ZAIKEI_JUTAKU_KINYU: 16,
  VITARITY_SMART: 17,
  RAKUTEN_UNYOU_SOUDAN: 18,
  FOUR_HUNDRED_F_INSURANCE_COMPARISON: 19,
  MONEY_FORWARD: 20,
  NINPATH: 26,
} as const;

// spaとssrで共通化するために便宜的においてるもの。
// 本来interfaceadapterにあるべきだが、
// spaの方すぐ消して不要になると思うので、まとめてここに定義してる。
// TODO: spaの処理消したらこれも消す
export const INFLOW_CHANNEL_QUERY_NAME = 'inflow_channel';

export type ChannelId = ValueOf<typeof CHANNEL_ID_MAP>;
export type Channel = keyof typeof CHANNEL_ID_MAP;

export const DEFAULT_CHANNEL: Channel = 'OKANEKO';
export const DEFAULT_CHANNEL_ID: ChannelId = CHANNEL_ID_MAP[DEFAULT_CHANNEL];

export const isChannelId = (value: number): value is ChannelId =>
  (Object.values(CHANNEL_ID_MAP) as number[]).includes(value);

export const toChannel = (channelId: ChannelId): Channel =>
  (Object.keys(CHANNEL_ID_MAP) as Channel[]).find(
    (key) => CHANNEL_ID_MAP[key] === channelId
  ) as unknown as Channel;
