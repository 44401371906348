import revive_payload_client_vr35M2cEQz from "/home/runner/_work/shadow/shadow/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.9_db0@0.2.1_eslint@9.21.0_jiti@2.4._1402de71b07b515abb6f0c34345cdb6e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Z8bc9t6djt from "/home/runner/_work/shadow/shadow/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.9_db0@0.2.1_eslint@9.21.0_jiti@2.4._1402de71b07b515abb6f0c34345cdb6e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_zdOVegRVfr from "/home/runner/_work/shadow/shadow/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.9_db0@0.2.1_eslint@9.21.0_jiti@2.4._1402de71b07b515abb6f0c34345cdb6e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_n0boHLPZIf from "/home/runner/_work/shadow/shadow/node_modules/.pnpm/nuxt-site-config@3.1.3_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_pDiozWUzJC from "/home/runner/_work/shadow/shadow/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.9_db0@0.2.1_eslint@9.21.0_jiti@2.4._1402de71b07b515abb6f0c34345cdb6e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_r6gyCOK8n4 from "/home/runner/_work/shadow/shadow/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.9_db0@0.2.1_eslint@9.21.0_jiti@2.4._1402de71b07b515abb6f0c34345cdb6e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_FwDyoAqEg1 from "/home/runner/_work/shadow/shadow/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.9_db0@0.2.1_eslint@9.21.0_jiti@2.4._1402de71b07b515abb6f0c34345cdb6e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_mSHeQFUrbx from "/home/runner/_work/shadow/shadow/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.9_db0@0.2.1_eslint@9.21.0_jiti@2.4._1402de71b07b515abb6f0c34345cdb6e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/_work/shadow/shadow/packages/app/.nuxt/components.plugin.mjs";
import prefetch_client_qA2BWkbvVo from "/home/runner/_work/shadow/shadow/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.9_db0@0.2.1_eslint@9.21.0_jiti@2.4._1402de71b07b515abb6f0c34345cdb6e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_pDuv3Hw8pS from "/home/runner/_work/shadow/shadow/node_modules/.pnpm/nuxt-jsonld@2.1.0_typescript@5.8.2/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_client_rGHeJH8c9Q from "/home/runner/_work/shadow/shadow/node_modules/.pnpm/nuxt-gtag@3.0.2_magicast@0.3.5_rollup@4.27.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import index_li7utzEKRk from "/home/runner/_work/shadow/shadow/packages/app/src/modules/stickyQueryParams/runtime/plugins/index.ts";
import gtm_client_MDucwXExS9 from "/home/runner/_work/shadow/shadow/packages/theme/plugins/gtm.client.ts";
import preloadFont_7POaN97N9M from "/home/runner/_work/shadow/shadow/packages/theme/plugins/preloadFont.ts";
import seoShowDirective_M9oEkJeCHD from "/home/runner/_work/shadow/shadow/packages/theme/plugins/seoShowDirective.ts";
import vueuse_gesture_yyVpDGL6qF from "/home/runner/_work/shadow/shadow/packages/theme/plugins/vueuse-gesture.ts";
import vueuse_motion_7QO18lTfTy from "/home/runner/_work/shadow/shadow/packages/theme/plugins/vueuse-motion.ts";
import _1_apollo_wNaIMwOSVc from "/home/runner/_work/shadow/shadow/packages/app/src/plugins/1.apollo.ts";
import _2_authentication_client_nEpRcuBVBQ from "/home/runner/_work/shadow/shadow/packages/app/src/plugins/2.authentication.client.ts";
import bodyScrollControll_client_VmAw2Vf2I0 from "/home/runner/_work/shadow/shadow/packages/app/src/plugins/bodyScrollControll.client.ts";
import logger_client_c79AJI4bSJ from "/home/runner/_work/shadow/shadow/packages/app/src/plugins/logger.client.ts";
import partner_client_DOwAJsy6No from "/home/runner/_work/shadow/shadow/packages/app/src/plugins/partner.client.ts";
import sentry_client_KAXFuL2wum from "/home/runner/_work/shadow/shadow/packages/app/src/plugins/sentry.client.ts";
import session_tHUolM7wCZ from "/home/runner/_work/shadow/shadow/packages/app/src/plugins/session.ts";
import testAttribute_TEr90mhyTJ from "/home/runner/_work/shadow/shadow/packages/app/src/plugins/testAttribute.ts";
import updateChannel_client_o1ic3W2fiP from "/home/runner/_work/shadow/shadow/packages/app/src/plugins/updateChannel.client.ts";
export default [
  revive_payload_client_vr35M2cEQz,
  unhead_Z8bc9t6djt,
  router_zdOVegRVfr,
  _0_siteConfig_n0boHLPZIf,
  payload_client_pDiozWUzJC,
  navigation_repaint_client_r6gyCOK8n4,
  check_outdated_build_client_FwDyoAqEg1,
  chunk_reload_client_mSHeQFUrbx,
  components_plugin_KR1HBZs4kY,
  prefetch_client_qA2BWkbvVo,
  plugin_pDuv3Hw8pS,
  plugin_client_rGHeJH8c9Q,
  index_li7utzEKRk,
  gtm_client_MDucwXExS9,
  preloadFont_7POaN97N9M,
  seoShowDirective_M9oEkJeCHD,
  vueuse_gesture_yyVpDGL6qF,
  vueuse_motion_7QO18lTfTy,
  _1_apollo_wNaIMwOSVc,
  _2_authentication_client_nEpRcuBVBQ,
  bodyScrollControll_client_VmAw2Vf2I0,
  logger_client_c79AJI4bSJ,
  partner_client_DOwAJsy6No,
  sentry_client_KAXFuL2wum,
  session_tHUolM7wCZ,
  testAttribute_TEr90mhyTJ,
  updateChannel_client_o1ic3W2fiP
]