export const targetQueryKeys = [
  /* マーケティング用 */
  'utm_source', // 内部（redash,GA）計測用
  'utm_medium', // 内部（redash,GA）計測用
  'utm_campaign', // 内部（redash,GA）計測用
  'utm_content', // 内部（redash,GA）計測用
  '4fmkfrom', // 内部（redash,GA）計測用
  'fbclid', // 内部（redash,GA）計測用
  'fmcs', // felmat計測用
  'fil', // afb計測用
  'Tcsack', // TCS計測用
  'rid', // i-mobile計測用
  'campaignid', // 内部（redash）計測用
  'adgroupid', // 内部（redash）計測用
  'feeditemid', // 内部（redash）計測用
  'extensionid', // 内部（redash）計測用
  'targetid', // 内部（redash）計測用
  'matchtype', // 内部（redash）計測用
  'device', // 内部（redash）計測用
  'creative', // 内部（redash）計測用
  'keyword', // 内部（redash）計測用
  'adposition', // 内部（redash）計測用
  'gclid', // 内部（redash）計測用
  'yclid', // 内部（redash）計測用
  '_ckey', // medipartner計測用
  '_cid', // medipartner計測用
  '_expire', // medipartner計測用
  'vc_lpp', // valuecommerce計測用
  'AFID', // presco計測用
  'fmak', // felmatダイレクトリンク計測用

  /* エンタープライズ用 */
  'financial_services_agent', // 代理店登録用

  'exclusive',
];
