export const toServerEnv = (envString: string): ServerEnv => {
  switch (envString) {
    case 'local':
    case 'alpha':
    case 'demo':
    case 'staging':
    case 'production':
      return envString;
    default:
      return 'production';
  }
};
