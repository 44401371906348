import type { Credential_, Options } from './Credential';
import { CredentialManager } from './CredentialManager';
import type { CredentialStore } from './CredentialStore';
import type { CredentialValidator } from './CredentialValidator';
import { graphql } from '@/gql';
import type { ResultOf } from '@graphql-typed-document-node/core';
import { print } from 'graphql/language/printer';
import { FetchError } from 'ofetch';
import { sleep } from '~/utils/sleep';

export class HotelToken implements Credential_<HotelToken> {
  private token: string;

  constructor(token: string) {
    this.token = token;
  }

  serialize(): string {
    return this.token;
  }

  modifyHeader(
    header: Record<string, string>,
    options?: Options
  ): Record<string, string> {
    return {
      ...header,
      [options?.overloadHeaderKey ?? 'x-api-token']: this.token,
    };
  }

  equals(other: HotelToken | null): boolean {
    return this.token === other?.token;
  }
}

class ErrorWrapper extends Error {
  errors: unknown[];
  constructor(errors: unknown[]) {
    super('error が あるよ ${this.errors}', { cause: errors });
    this.errors = errors;
  }
}

export const validationQuery = graphql(`
  query ValidateToken {
    viewer2 {
      __typename
      ... on ViewerQuerySuccessPayload {
        result {
          id
        }
      }
      ... on ViewerQueryErrorPayload {
        error
      }
    }
  }
`);

class HotelTokenValidator implements CredentialValidator<HotelToken> {
  private hotelCli = $fetch.create({ baseURL: useServiceEndpoints().hotel });
  private federationGatewayCli = $fetch.create({
    baseURL: useServiceEndpoints().federationGateway,
  });

  async validate(
    credential: HotelToken,
    numOfRetries: number = 0
  ): Promise<boolean> {
    const retry = async () => {
      await sleep(
        numOfRetries < 5
          ? numOfRetries * 1000
          : Math.min(numOfRetries, 10) * 3000
      );
      return await this.validate(credential, numOfRetries + 1);
    };
    try {
      const { data, errors } = await this.federationGatewayCli<{
        data?: ResultOf<typeof validationQuery>;
        errors?: unknown[];
      }>('gateway/user/query', {
        method: 'POST',
        headers: credential.modifyHeader({
          'content-type': 'application/json',
        }),
        body: JSON.stringify({ query: print(validationQuery) }),
      });

      if (errors != undefined && errors.length > 0) {
        // error が throw されたりされなかったりで例外処理があちこちに散らばっ
        // てコードの見通しが悪くなるので、 goto っぽく throw 使ってる感じがし
        // てあんまよくないけど一旦例外系に飛ばす
        throw new ErrorWrapper(errors);
      }
      // federatoin gateway が 401 のとき data を null で投げてくるっぽいので現
      // 状は必要ない（というか catch 節に飛ぶので unreachable）けど一応置いとく
      if (data?.viewer2?.__typename === 'ViewerQueryErrorPayload') {
        return false;
      }
    } catch (e) {
      // federatoin gateway を使ってる限りは FetchError が 401 で飛んでくること
      // はなくて ErrorWrapper で確認できるけど変更されうるのでこっちにも置いと
      // く
      if (e instanceof FetchError && e.status === 401) {
        return false;
      }
      // federatoin gateway が 401 を errors にまとめるのでこっちで401のチェッ
      // クをしないといけない
      if (
        e instanceof ErrorWrapper &&
        e.errors?.some(
          (e) =>
            e instanceof Object &&
            'extensions' in e &&
            e.extensions instanceof Object &&
            'code' in e.extensions &&
            e.extensions?.code === 'UNAUTHENTICATED'
        )
      ) {
        return false;
      }

      return await retry();
    }
    return true;
  }

  async invalidate(credential: HotelToken): Promise<void> {
    await this.hotelCli('user/logout/', {
      method: 'POST',
      headers: credential.modifyHeader({}),
    });
  }
}

class HotelTokenStore implements CredentialStore<HotelToken> {
  // field に const 使えない 定数 hack
  private static get STORAGE_KEY(): string {
    return 'apiToken';
  }

  load(): HotelToken | null {
    const token = window.localStorage.getItem(HotelTokenStore.STORAGE_KEY);
    return token ? new HotelToken(token) : null;
  }

  clear(): void {
    window.localStorage.removeItem(HotelTokenStore.STORAGE_KEY);
  }

  save(credential: HotelToken): void {
    window.localStorage.setItem(
      HotelTokenStore.STORAGE_KEY,
      credential.serialize()
    );
  }

  setOnStoredValueChangedListener(
    listener: (credential: HotelToken | null) => PromiseOrValue<void>
  ): void {
    window.addEventListener('storage', (event: StorageEvent) => {
      if (event.key !== HotelTokenStore.STORAGE_KEY) {
        return;
      }
      listener(this.load());
    });
  }
}

export class HotelTokenManager
  extends CredentialManager<HotelToken>
  implements PromiseLike<{ initialized: HotelTokenManager }>
{
  private initializationPromise: Promise<{ initialized: HotelTokenManager }>;
  constructor() {
    super(new HotelTokenValidator(), new HotelTokenStore());
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.initializationPromise = this.validateCurrentTokenAndRemoveIfNeeded()
      // hack: 自身が自身の promise like なので 自身をそのまま返してしまうと
      // promise の unwrap が無限再帰する。object にくるむことで then メソッド
      // を隠して 無限再帰を回避してる
      .then(() => ({ initialized: self }));
  }

  // 検証済みの token がほしいときに await することで初回の token 検証が終わる
  // のを待つことができるようにしてる
  then<Result>(
    onInitialized: (value: {
      initialized: HotelTokenManager;
    }) => Result | PromiseLike<Result>
  ): PromiseLike<Result> {
    return this.initializationPromise.then(onInitialized);
  }
}
