import {
  type APIEndpoints,
  resolveAPIEndpoints,
} from '@400f/frontend-constants';

const alphaEndpoints = resolveAPIEndpoints('alpha');
const localEndpoints = {
  ...alphaEndpoints.apis,

  /* 🙈 localで立てたエンドポイントだけコメントアウト外して使う 🙉 */

  // derek: 'http://localhost:5000/api/v1' as any,
  // cinnamon: 'http://localhost:3001/api/v1' as any,
  // cinnamonWs: 'ws://localhost:3001/api/v1' as any,

  // ローカルPCだけでLINEログインの開発をするときはこちら
  // lexington: 'https://lexington.local.400f.jp:5350/api/v1' as any,
  // 実機などPC外でLINEログインの開発をするときはこちら
  // lexington: 'https://lexington.ngrok.400f.jp/api/v1' as any,

  // cuckoo: 'http://localhost:3002/api/v1' as any,
  // bigbrother: 'http://localhost:3001/v1' as any,
  // call: 'http://localhost:3004/v1' as any,
  // imgix: 'okane-kenko-dev.imgix.net' as any,
  // vurivuri: 'http://localhost:3007/v1' as any,
  // hotel: 'http://localhost:3009' as any,
  // federationGateway: 'http://localhost:8282' as any,
} as const satisfies APIEndpoints;

export const resolveServerEndpoints = (env: ServerEnv) => {
  if (env === 'local') return localEndpoints;
  const { apis } = resolveAPIEndpoints(env);
  return apis;
};
