// eslint-disable-next-line no-restricted-imports
import { useSeoMeta } from '#imports';
import { generateAppMetaDescription } from './description';
import { generateAppMetaImage } from './image';
import { generateAppMetaTitle } from './title';
import { generateAppMetaUrl } from './url';
import type { Link } from '@unhead/schema';

type AppMetaStaticArgs = {
  titleSuffix: string;
  defaultTitle: string;
  defaultDescription: string;
  defaultOgImagePath: string;
};

export type AppMetaArgs = {
  title?: MaybeRefOrGetter<Maybe<string>>;
  hasTitleSuffix?: boolean; // リアクティブに変化するユースケースはおそらく無い
  description?: MaybeRefOrGetter<Maybe<string>>;
  urlPath?: MaybeRefOrGetter<Maybe<string>>;
  imageUrl?: MaybeRefOrGetter<Maybe<string>>; // image urlの方が優先度高い
  imagePath?: MaybeRefOrGetter<Maybe<string>>; // いちいちappOriginを引いてきてurlを作成しないですむようにpathのインターフェースも用意してる
  noindex?: MaybeRefOrGetter<boolean>;
  iconPath?: MaybeRefOrGetter<Maybe<string>>;
};

export const createUseAppMeta =
  (staticArgs: AppMetaStaticArgs) => (args: AppMetaArgs) => {
    const {
      public: { appOrigin },
    } = useRuntimeConfig();

    const title = () =>
      generateAppMetaTitle({
        title: toValue(args.title),
        hasTitleSuffix: args.hasTitleSuffix,
        titleSuffix: staticArgs.titleSuffix,
        defaultTitle: staticArgs.defaultTitle,
      });

    const description = () =>
      generateAppMetaDescription({
        description: toValue(args.description),
        defaultDescription: staticArgs.defaultDescription,
      });
    const url = () =>
      generateAppMetaUrl(appOrigin, {
        urlPath: toValue(args.urlPath),
      });
    const imageUrl = () =>
      generateAppMetaImage(appOrigin, {
        imageUrl: toValue(args.imageUrl),
        imagePath: toValue(args.imagePath),
        defaultOgImagePath: staticArgs.defaultOgImagePath,
      });
    const robots = () =>
      toValue(args.noindex) ? { noindex: true, nofollow: true } : {};

    const route = useRoute();
    const canonicalUrl = (): string =>
      toValue(url) ?? appOrigin + route.path.replace(/\/+$/, '');

    useSeoMeta({
      title,
      ogTitle: title,
      description,
      ogDescription: description,
      ogImage: imageUrl,
      twitterCard: 'summary_large_image',
      ogUrl: url,
      robots,
    });
    useHead({
      link: () => {
        const links: Link[] = [
          {
            rel: 'canonical',
            href: toValue(canonicalUrl),
          },
        ];

        const iconPath = toValue(args.iconPath);
        if (iconPath) {
          links.push({
            rel: 'icon',
            type: 'image/x-icon',
            href: iconPath,
          });
        }

        return links;
      },
    });

    if (!route.path.startsWith('/products/')) {
      useJsonld(
        [
          {
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            name: 'オカネコ - 3分でかんたん家計診断',
            url: useConstants().WEB.homePage,
            description: useConstants().WEB.description,
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': toValue(canonicalUrl),
              description: description(),
            },
            publisher: {
              '@type': 'Organization',
              name: useConstants().CORPORATION.name,
            },
          },
          {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name: useConstants().CORPORATION.name,
            url: useConstants().CORPORATION.webPage,
            foundingDate: useConstants().CORPORATION.foundingDate,
            founder: useConstants().CORPORATION.founder,
            image: {
              '@type': 'ImageObject',
              url: useConstants().CORPORATION.image.url,
            },
            address: {
              '@type': 'PostalAddress',
              streetAddress: useConstants().CORPORATION.address.streetAddress,
              addressLocality:
                useConstants().CORPORATION.address.addressLocality,
              addressRegion: useConstants().CORPORATION.address.addressRegion,
              postalCode: useConstants().CORPORATION.address.postalCode,
              addressCountry: 'JP',
              telephone: useConstants().CORPORATION.telephone,
            },
          },
          {
            '@context': 'https://schema.org',
            '@type': 'InsuranceAgency',
            name: 'オカネコ - 3分でかんたん家計診断',
            url: 'https://okane-kenko.jp/insurance',
            parentOrganization: {
              '@type': 'Corporation',
              url: useConstants().CORPORATION.webPage,
              name: useConstants().CORPORATION.name,
            },
            image: {
              '@type': 'ImageObject',
              url: 'https://okane-kenko.jp/assets_insurance/ogp/default.png',
            },
            address: {
              '@type': 'PostalAddress',
              streetAddress: useConstants().CORPORATION.address.streetAddress,
              addressLocality:
                useConstants().CORPORATION.address.addressLocality,
              addressRegion: useConstants().CORPORATION.address.addressRegion,
              postalCode: useConstants().CORPORATION.address.postalCode,
              addressCountry: 'JP',
              telephone: useConstants().CORPORATION.telephone,
            },
          },
        ],
        {
          tagPosition: 'bodyClose',
        }
      );
    }
  };
