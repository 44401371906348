import type { Credential_ } from './Credential';
import type { CredentialStore } from './CredentialStore';
import type { CredentialValidator } from './CredentialValidator';

type OnBeforeChangeListener<T> = (
  new_: T | null,
  old: T | null
) => PromiseOrValue<void>;

type OnChangedListener<T> = (credential: T | null) => PromiseOrValue<void>;

export abstract class CredentialManager<T extends Credential_<T>> {
  private onBeforeChangeListeners: OnBeforeChangeListener<T>[] = [];
  private onChangedListeners: OnChangedListener<T>[] = [];

  private store: CredentialStore<T>;
  private validator: CredentialValidator<T>;
  private credential_: InstanceType<typeof Observable<T | null>>;

  get credential(): T | null {
    return this.credential_.value;
  }

  constructor(validator: CredentialValidator<T>, store: CredentialStore<T>) {
    this.validator = validator;
    this.store = store;

    this.credential_ = new Observable(this.store.load(), (new_, old) => {
      if (new_?.equals(old) ?? new_ === old) {
        return;
      }
      if (new_ === null) {
        this.store.clear();
        return;
      }
      this.store.save(new_);
    });

    this.store.setOnStoredValueChangedListener((new_) => {
      if (new_?.equals(this.credential) ?? new_ == this.credential) {
        return;
      }
      if (new_ === null) {
        this.invalidateCredential();
        return;
      }
      this.updateCredential(new_);
    });
  }

  async updateCredential(v: T): Promise<void> {
    if (!(await this.validator.validate(v))) return;
    await Promise.all(
      this.onBeforeChangeListeners.map((l) => l(v, this.credential))
    );
    this.credential_.value = v;
    await Promise.all(this.onChangedListeners.map((l) => l(v)));
  }

  async invalidateCredential(): Promise<void> {
    const c = this.credential;
    if (c === null) return;
    await Promise.all(this.onBeforeChangeListeners.map((l) => l(null, c)));
    await this.validator.invalidate(c).catch(captureException);
    this.credential_.value = null;
    await Promise.all(this.onChangedListeners.map((l) => l(null)));
  }

  async validateCurrentTokenAndRemoveIfNeeded(): Promise<void> {
    const c = this.credential;
    if (c === null) return;
    if (await this.validator.validate(c)) return;
    await this.invalidateCredential();
  }

  addOnBeforeCredentialChangeListener(
    listener: (new_: T | null, old: T | null) => PromiseOrValue<void>
  ): void {
    this.onBeforeChangeListeners = [...this.onBeforeChangeListeners, listener];
  }
  removeOnBeforeCredentialChangeListener(
    listener: (new_: T | null, old: T | null) => PromiseOrValue<void>
  ): void {
    this.onBeforeChangeListeners = this.onBeforeChangeListeners.filter(
      (l) => l !== listener
    );
  }

  addOnCredentialChangedListener(
    listener: (credential: T | null) => PromiseOrValue<void>
  ): void {
    this.onChangedListeners = [...this.onChangedListeners, listener];
  }
  removeOnCredentialChangedListener(
    listener: (credential: T | null) => PromiseOrValue<void>
  ): void {
    this.onChangedListeners = this.onChangedListeners.filter(
      (l) => l !== listener
    );
  }
}
