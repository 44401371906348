import './seo-show-directive.scss';

/**
 * v-seo-show directive
 *
 * 視覚ブラウザのユーザー表示からは消したいが、SEOのためにアクセシビリティツリーから消したくない要素に対して使用するディレクティブ
 * アクセシビリティ対策でも使えるっちゃ使える
 */
export default defineNuxtPlugin((nuxt) => {
  nuxt.vueApp.directive('seo-show', (el, binding) => {
    if (!(el instanceof HTMLElement)) {
      return;
    }
    const show = binding.value;
    if (typeof show !== 'boolean') {
      return;
    }
    if (show) {
      el.classList.remove('hide-accessible-content');
    } else {
      el.classList.add('hide-accessible-content');
    }
  });
});
