import type { ChannelId } from '~/entity/channel';
import { CHANNEL_ID_MAP } from '~/entity/channel';

const parseChannelId = (path: string): ChannelId | null => {
  const regexp = /\/partner\/(\w+)\/*/;
  const channelRegexpArray = path.match(regexp);

  const channel = channelRegexpArray
    ? // URL はケースインセンシティブであるべきなので大文字が来てもいいようにする
      channelRegexpArray[1].toLowerCase()
    : null;

  switch (channel) {
    case 'rakuten':
      return CHANNEL_ID_MAP.RAKUTEN;
    case 'yahoo':
      return CHANNEL_ID_MAP.YAHOO;
    case 'sbi':
      return CHANNEL_ID_MAP.SBI;
    case 'ponta':
      return CHANNEL_ID_MAP.PONTA;
    case 'okb':
      return CHANNEL_ID_MAP.OKB;
    case 'kiraboshi':
      return CHANNEL_ID_MAP.KIRABOSHI;
    case '400f':
    case '400f_2':
      return CHANNEL_ID_MAP.FOUR_HUNDRED_F;
    case 'hanasaku':
      return CHANNEL_ID_MAP.HANASAKU;
    case 'hokennomadoguchi':
    case 'hokennomadoguchi_tsuhan':
      return CHANNEL_ID_MAP.HOKEN_NO_MADOGUCHI;
    case 'smcc':
      return CHANNEL_ID_MAP.SMCC;
    case 'famipay':
      return CHANNEL_ID_MAP.FAMIPAY;
    case 'rakuten_inhouseadvisor':
      return CHANNEL_ID_MAP.RAKUTEN_IN_HOUSE_ADVISOR;
    case 'zjk':
      return CHANNEL_ID_MAP.ZAIKEI_JUTAKU_KINYU;
    case 'moneyforward':
      return CHANNEL_ID_MAP.MONEY_FORWARD;
    case 'ninpath':
      return CHANNEL_ID_MAP.NINPATH;
    default:
      return null;
  }
};

export default defineNuxtPlugin(() => {
  const route = useRoute();
  const { setChannelId } = useChannelId();

  const channelId = parseChannelId(route.path);
  if (channelId !== null) {
    setChannelId(channelId);
  }

  const router = useRouter();
  router.beforeEach(async (to, _, next) => {
    const channelId = parseChannelId(to.path);
    if (channelId !== null) {
      setChannelId(channelId);
    }
    next();
  });
});
