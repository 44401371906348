<script lang="ts">
import { mergeProps } from 'vue';
import {
  type Size,
  type Variant,
  sizes,
  variants,
} from '~theme/components/atoms/styles/ButtonFlatStyle.vue';

export { sizes, variants };
export type { Size, Variant };
</script>

<script lang="ts" setup>
import type { NuxtLinkProps } from 'nuxt/app';
import type { SetupContext } from 'vue';
import ButtonFlatStyle from '~theme/components/atoms/styles/ButtonFlatStyle.vue';

interface Props extends /* @vue-ignore */ NuxtLinkProps {
  variant?: Variant;
  size?: Size;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  variant: 'secondary',
  size: 'medium',
  disabled: false,
  external: undefined,
  replace: undefined,
  custom: undefined,
  target: null,
  rel: null,
  noRel: undefined,
  prefetch: undefined,
  noPrefetch: undefined,
});

const attrs: SetupContext['attrs'] = useAttrs();
const buttonProps = computed(() => {
  const {
    variant: _variant,
    size: _size,
    disabled: _disabled,
    ...rest
  } = props;
  return mergeProps(attrs, rest);
});

defineOptions({
  inheritAttrs: false,
});
</script>

<template>
  <ButtonFlatStyle :variant="variant" :size="size" :disabled="disabled">
    <span v-if="disabled" v-bind="$attrs">
      <slot />
    </span>
    <NuxtLink v-else v-bind="buttonProps">
      <slot />
    </NuxtLink>
  </ButtonFlatStyle>
</template>
