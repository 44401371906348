import StickyQueryParamsRouterLink from '../components/StickyQueryParamsRouterLink.vue';
import { defineNuxtPlugin } from 'nuxt/app';

export default defineNuxtPlugin({
  setup(nuxtApp) {
    // FIXME: 上書きするなエラーを阻止するための悪い方法
    // RouterLink周りの調子がおかしくなったらこれ消してください
    delete nuxtApp.vueApp._context.components['RouterLink'];
    nuxtApp.vueApp.component('RouterLink', StickyQueryParamsRouterLink);
  },
});
