export const useSession = () => {
  const shortSessionId = useState<string>();
  const longSessionId = useState<string>();

  return {
    shortSessionId: computed(() => shortSessionId.value),
    longSessionId: computed(() => longSessionId.value),
    setSession: (long: string, short: string) => {
      if (!import.meta.server) {
        setTag('long_session_id', long);
      }
      shortSessionId.value = short;
      longSessionId.value = long;
    },
  };
};
