import routerOptions0 from "/home/runner/_work/shadow/shadow/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.9_db0@0.2.1_eslint@9.21.0_jiti@2.4._1402de71b07b515abb6f0c34345cdb6e/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/home/runner/_work/shadow/shadow/packages/app/src/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}