import generatedIntrospection from '@@/introspection.generated';
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/vue';
import { ApolloClients, provideApolloClients } from '@vue/apollo-composable';
import type { HotelToken } from '~/composables/useCredentialManager/hotel';

declare global {
  interface Window {
    __APOLLO_CLIENT__: ApolloClient<unknown>;
  }
}

declare module '#app' {
  interface NuxtApp {
    $apollo: {
      defaultClient: ApolloClient<unknown>;
    };
  }
}

const generateApolloLink = (token?: Ref<HotelToken | null>) => {
  const authLink = setContext((_, { headers }) => {
    return {
      headers: token?.value?.modifyHeader(headers) ?? headers,
    };
  });

  const { federationGateway } = useServiceEndpoints();
  const httpLink = createHttpLink({
    uri: `${federationGateway}/gateway/user/query`,
    credentials: 'include',
  });

  const errorLink = onError(({ operation, response, graphQLErrors }) => {
    const setExtras =
      // なぜか Sentry.setExtras is not a functionになる現象を回避
      // 型と実体があってない、CJSなのにdefault export持っていたりするので力技で抜き出す
      // 一致したときのために Sentry.setExtras に fallback する
      // v8でESM対応入るらしいのでそれまでの暫定処理
      (
        Sentry as unknown as {
          // eslint-disable-next-line @typescript-eslint/consistent-type-imports
          default?: { setExtras: typeof import('@sentry/vue').setExtras };
        }
      ).default?.setExtras ?? Sentry.setExtras;
    setExtras({
      gqlOperation: operation.operationName,
      gqlVariables: JSON.stringify(operation.variables),
      gqlResponse: JSON.stringify(response),
      gqlErrors: JSON.stringify(graphQLErrors),
    });
  });

  return ApolloLink.from([errorLink, authLink, httpLink]);
};

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { serverEnv },
  } = useRuntimeConfig();
  const link = generateApolloLink();
  const cache = new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    typePolicies: {
      ViewerQueryPayload: {
        merge: false,
      },
      ViewerQueryErrorPayload: {
        merge: false,
      },
      Viewer: {
        keyFields: [],
      },
    },
  });

  const defaultClient = new ApolloClient({
    cache,
    link,
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
    },
    ...(import.meta.server
      ? {
          ssrMode: true,
        }
      : {
          // キャッシュをhydrateする前にクエリが飛ぶのを抑制する
          ssrForceFetchDelay: 100,
        }),
  });

  if (!import.meta.server) {
    useHotelToken().then(({ token }) =>
      defaultClient.setLink(generateApolloLink(token))
    );
  }

  // 開発環境だったら apollo chrome extensionを使えるようにする
  // ref: https://github.com/apollographql/apollo-client-devtools#configuration
  if (import.meta.client && serverEnv === 'local') {
    window.__APOLLO_CLIENT__ = defaultClient;
  }

  const clients = {
    default: defaultClient,
  };
  provideApolloClients(clients);
  nuxtApp.vueApp.provide(ApolloClients, clients);

  const cacheKey = '_apollo:default';
  nuxtApp.hook('app:rendered', () => {
    nuxtApp.payload.data[cacheKey] = cache.extract();
  });

  if (import.meta.client && nuxtApp.payload.data[cacheKey]) {
    // データがproxyされているので、素のobjectに戻す
    const proxyRestoredCache = nuxtApp.payload.data[cacheKey];
    cache.restore(proxyRestoredCache);
  }

  return {
    provide: {
      apollo: {
        defaultClient,
      },
    },
  };
});
