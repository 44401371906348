import type { AsyncDataOptions } from '#app';
import { useApolloClient } from './useApolloClient';
import type { OperationVariables, QueryOptions } from '@apollo/client';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { print } from 'graphql';
import { hash } from 'ohash';

type AsyncQueryApolloOptions = Omit<
  QueryOptions<OperationVariables, unknown>,
  'query' | 'variables'
>;
type AsyncQueryAsyncDataOptions<ResT> = Omit<
  AsyncDataOptions<ResT>,
  'getCachedData' | 'transform' | 'default' | 'pick'
>;
export type AsyncQueryOptions<ResT> = {
  apollo?: AsyncQueryApolloOptions;
  asyncData?: AsyncQueryAsyncDataOptions<ResT>;
};

/**
 * SSRで取得したデータをhydrationでCSRに渡すcomposable。
 * awaitをつけると非同期コンポーネントとして、結果が返ってくるまでレンダリングを遅延してくれる。
 * awaitをつけない場合、同期コンポーネントとしてレンダリングされるが、
 * promiseがresolveされるまでナビゲーションを遅延してくれる。
 */
export function useAsyncQuery<TResults, TVariables extends OperationVariables>(
  query: TypedDocumentNode<TResults, TVariables>,
  variables?: MaybeRefOrGetter<TVariables>,
  opts?: AsyncQueryOptions<TResults>
) {
  const { key, fn } = _generateAsyncQueryFunction(
    query,
    variables,
    opts?.apollo
  );

  const result = useAsyncData<TResults>(key, fn, opts?.asyncData);
  const originalRefresh = result.refresh;
  result.refresh = async (...args) => {
    // isHydration === trueの場合、refreshをしてもキャッシュを参照してしまうので、
    // 明示的にhydrationが終わるまで待つ
    await waitHydrated();
    return originalRefresh(...args);
  };

  return result;
}

type LazyAsyncQueryApolloOptions = Omit<
  QueryOptions<OperationVariables, unknown>,
  'query' | 'variables'
>;
type LazyAsyncQueryAsyncDataOptions<ResT> = Omit<
  AsyncDataOptions<ResT>,
  'getCachedData' | 'transform' | 'default' | 'pick' | 'lazy' | 'immediate'
>;
export type LazyAsyncQueryOptions<ResT> = {
  apollo?: LazyAsyncQueryApolloOptions;
  asyncData?: LazyAsyncQueryAsyncDataOptions<ResT>;
};

/**
 * promiseがresolveされるまでナビゲーションを遅延しない。
 * execute()を呼ぶまでクエリを実行しない。
 */
export function useLazyAsyncQuery<
  TResults,
  TVariables extends OperationVariables,
>(
  query: TypedDocumentNode<TResults, TVariables>,
  variables?: MaybeRefOrGetter<TVariables>,
  opts?: LazyAsyncQueryOptions<TResults>
) {
  const { key, fn } = _generateAsyncQueryFunction(
    query,
    variables,
    opts?.apollo
  );

  const result = useLazyAsyncData<TResults>(key, fn, {
    ...opts?.asyncData,
    immediate: false,
  });
  const originalRefresh = result.refresh;
  result.refresh = async (...args) => {
    // isHydration === trueの場合、refreshをしてもキャッシュを参照してしまうので、
    // 明示的にhydrationが終わるまで待つ
    await waitHydrated();
    return originalRefresh(...args);
  };

  return result;
}

const _generateAsyncQueryFunction = <
  TResults,
  TVariables extends OperationVariables,
>(
  query: TypedDocumentNode<TResults, TVariables>,
  variables?: MaybeRefOrGetter<TVariables>,
  apolloOpts?: AsyncQueryApolloOptions
) => {
  const {
    clients: { default: defaultClient },
  } = useApolloClient();

  const key = hash({ query: print(query), variables });
  const fn = async () => {
    const { data } = await defaultClient.query<TResults, TVariables>({
      query: query,
      variables: toValue(variables),
      ...apolloOpts,
    });
    return data;
  };

  return { key, fn };
};
