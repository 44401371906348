import type { LocationQueryValueRaw } from '#vue-router';
import type { RouterConfig } from '@nuxt/schema';

const appendQueryValueRaw = (
  query: URLSearchParams,
  key: string,
  value: LocationQueryValueRaw
) => {
  const qv = value == null ? '' : value.toString();
  query.append(key, qv);
};

export const stringifyQuery: NonNullable<RouterConfig['stringifyQuery']> = (
  query
) => {
  return Object.entries(query)
    .reduce((acc, [k, v]) => {
      if (Array.isArray(v)) {
        v.map((value) => {
          appendQueryValueRaw(acc, k, value);
        });
        return acc;
      }
      appendQueryValueRaw(acc, k, v);
      return acc;
    }, new URLSearchParams())
    .toString()
    .replace(/=(?=&|$)/gm, ''); // 空文字のクエリパラメータは = を消したい
};
