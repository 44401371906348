import type { User } from '@sentry/vue';

export default defineNuxtPlugin(
  async ({
    vueApp,
    $config: {
      public: { sentryDsn, serverEnv, sentryVersion },
    },
  }) => {
    // テスト時は無効化する
    if (process.env.NODE_ENV === 'test') return {};

    // dynamic import することで、バンドルサイズを軽減する
    const Sentry = await import('@sentry/vue');

    Sentry.init({
      app: vueApp,
      enabled: serverEnv === 'production',
      dsn: sentryDsn,
      environment: serverEnv,
      release: sentryVersion,
      integrations: [
        Sentry.dedupeIntegration(),
        Sentry.globalHandlersIntegration({
          onerror: true,
          onunhandledrejection: true,
        }),
        Sentry.extraErrorDataIntegration({ depth: 5 }),
        Sentry.browserTracingIntegration(),
        // FIXME: warwnが出まくるので一旦コメントアウト
        // Sentry.replayIntegration(),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production -> 5% now
      tracesSampleRate: 0.01,
      // disable entire session capturing
      replaysSessionSampleRate: 0,
      // Capture Replay for 100% of error sessions
      replaysOnErrorSampleRate: 0.01,
      beforeSend: (event) => {
        console.info({ event }); // eslint-disable-line no-console
        return event;
      },
      ignoreErrors: [
        // clarity
        /Cannot read properties of null \(reading 'disconnect'\)/,
        /n.replace is not a function/,
        /clarity/,
        // hooper(carousel library)
        /undefined is not an object \(evaluating 't.touches\[0\]'\)/,
        // bigbrother投げてる途中でページ遷移すると起こる
        /XMLHttpRequestProgressEvent/,
        // 401
        /Unauthorized/,
        /Not Authenticated/,
        /Received status code 401/,
        /Request failed with status code 401/,
        /Response not successful: Received status code 401/,
        // 重複回答
        /Already Exists/,
        // Timeout
        /timeout/,
        /Request timed out/,
        // Network error
        /Network Error/,
        /Failed to fetch/,
        /Load failed/,
        /Request aborted/,
        /socket is not opened/,
        // 謎の拡張エラー
        /learningToolsRuntimeBridge/,
        // 404
        /アンケートが見つかりません/,
      ],
      denyUrls: [
        // local
        /localhost/,
        /127.0.0.1/,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        // local file
        /^file:\/\//i,
        /googletagmanager\.com/i,
      ],
    });

    return {
      provide: {
        sentry: {
          setUser: (user: Pick<User, 'id' | 'username'> | null) =>
            Sentry.setUser(user),
          setExtras: (extras: Record<string, unknown>) =>
            Sentry.setExtras(extras),
          captureMessage: (message: string) => Sentry.captureMessage(message),
          captureException: (error: unknown) => Sentry.captureException(error),
        },
      },
    };
  }
);
