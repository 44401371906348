<script setup lang="ts">
import { useStickyQuery } from '../composables/useStickyQuery';
import { injectQueryParamsToLocation } from '../utils/injectQueryParamsToLocation';
import { computed, useAttrs } from 'vue';
import {
  RouterLink as NativeRouterLink,
  type RouterLinkProps,
} from 'vue-router';

const props = defineProps<RouterLinkProps>();
const attrs = useAttrs();

const { targetQueries } = useStickyQuery();

const to = computed(() => {
  return injectQueryParamsToLocation(props.to, targetQueries.value);
});
const injectedAttrs = computed(() => {
  return {
    ...props,
    ...attrs,
    to: to.value,
  };
});
</script>

<template>
  <NativeRouterLink v-bind="injectedAttrs"><slot /></NativeRouterLink>
</template>
