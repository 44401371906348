type Callback<T extends unknown[]> = (...params: T) => Promise<void> | void;

export const useEventBus = <Params extends unknown[]>(key: string) => {
  const subscriptionCallbacks = useState<Callback<Params>[]>(key, () => []);
  const subscribe = (callback: Callback<Params>) => {
    subscriptionCallbacks.value = [...subscriptionCallbacks.value, callback];
  };
  const unsubscribe = (callback: Callback<Params>) => {
    subscriptionCallbacks.value = subscriptionCallbacks.value.filter(
      (f) => f !== callback
    );
  };

  const trigger = async (...params: Params) => {
    await Promise.all(subscriptionCallbacks.value.map((f) => f(...params)));
  };

  return {
    subscribe,
    unsubscribe,
    trigger,
  };
};
