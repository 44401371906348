import type { HotelToken } from './useCredentialManager/hotel';

// 依存の発生源が singleton なのでこっちも singleton にしとくと callbackとかの
// 寿命を考えるのが楽になるし状態の変更関係のコードをここに押し込められて嬉しい
const token = lazy(() => {
  const { tokenManager } = useHotelTokenManager();
  tokenManager.addOnBeforeCredentialChangeListener((new_) => {
    token.value = new_;
  });
  const token = ref<HotelToken | null>(tokenManager.credential);
  return token;
});

type Return = { token: ComputedRef<HotelToken | null> };

// TODO: deprecated を使ったときいい感じに lint 落としたいけど vue と併用できる
// いい感じの rule 見つけてない
/**
 * @deprecated
 *
 * useAuthenticationState の isAuthenticated が変更された時点で新しい token を
 * 利用することが保証されている必要がある API クライアントなどの更新のみで利用
 * すること
 *
 * この関数が返す token が変更された時点では API クライアント類の変更の完了を保
 * 証できないので、 token を watch してログイン状況の変化に応じた処理を走らせよ
 * うとした場合意図しない動作をする可能性がある
 */
export const useHotelToken = (): Return & PromiseLike<Return> => {
  const { tokenManager } = useHotelTokenManager();
  const result: Return = {
    token: computed(() => token.value.value as HotelToken | null),
  };
  const initializationPromise = tokenManager.then(() => result);
  return {
    ...result,
    then: (onfullfilled?, onrejected?) =>
      initializationPromise.then(onfullfilled, onrejected),
  };
};
